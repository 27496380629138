import Routes from "routes";
import ThemeCustomization from "themes";
import ScrollTop from "components/ScrollTop";
import ReactGA from "react-ga";
const TRACKING_ID = "UA-196247562-2";
// ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
// ReactGA.initialize(TRACKING_ID);

const App = () => (
  <ThemeCustomization sx={{ bgcolor: "primary.darker" }}>
    <ScrollTop>
      <Routes />
    </ScrollTop>
  </ThemeCustomization>
);

export default App;
