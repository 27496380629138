import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import "../pages/reports/DailyReport.css";
import YearlyReport from "pages/reports/YearlyReport";
import MyChart from "pages/reports/Performance";
import UploadFile, { upload, uploadFile } from "pages/reports/upload/upload";
// import MasterReport from "master/MasterForm";
import MasterList from "master/masterData/MasterList";
import EditMaster from "master/EditMaster";
import LoginForm from "../pages/authentication/auth-forms/Login-new";
import AuthLogin from "../pages/authentication/Login";
import MasterReport from "master/masterData/MasterForm-new";
import EditForm from "master/masterData/EditForm-new";

//site
import SiteCreate from "master/siteData/SiteCreate";
import SiteEdit from "master/siteData/SiteEdit";
import SiteList from "master/siteData/SiteList";

//turbine
import TurbineCreateForm from "master/turbineData/TurbineCreateForm";
import TurbineEditForm from "master/turbineData/TurbineEditForm";
import TurbineList from "master/turbineData/TurbineList";

//project master
import ProjectMasterCreate from "master/projectMaster/ProjectMasterCreate";
import ProjectMasterEdit from "master/projectMaster/ProjectMasterEdit";
import ProjectMasterList from "master/projectMaster/ProjectMasterList";

import TurbineTypeCreate from "master/turbineType/TurbineTypeCreate";
import TurbineTypeEdit from "master/turbineType/TurbineTypeEdit";
import TurbineTypeList from "master/turbineType/TurbineTypeList";

import TurbineUnitCreate from "master/turbineUnit/TurbineUnitCreate";
import TurbineUnitEdit from "master/turbineUnit/TurbineUnitEdit";
import TurbineUnitList from "master/turbineUnit/TurbineUnitList";
import LocationCreate from "master/location/LocationCreate";
import LocationList from "master/location/LocationList";
import LocatoinEdit from "master/location/LocationEdit";

import Userlist from "pages/user-management/Userlist";
import ViewProfile from "pages/user-management/ViewProfile";
import YearlyConsoleReport from "pages/reports/YearlyConsoleReport";
import { useSelector } from "react-redux";
// import ChangePassword from "pages/user-management/changePassword";

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));

// render - sample page
const SamplePage = Loadable(lazy(() => import("pages/extra-pages/SamplePage")));
const DailyReport = Loadable(lazy(() => import("pages/reports/DailyReport")));
const DataEntryForm = Loadable(lazy(() => import("master/DataEntryForm")));

const ChangePassword = Loadable(
  lazy(() => import("pages/user-management/changePwd"))
);

const MonthlyReport = Loadable(
  lazy(() => import("pages/reports/MonthlyReport"))
);

// render - utilities
const Typography = Loadable(
  lazy(() => import("pages/components-overview/Typography"))
);
const Color = Loadable(lazy(() => import("pages/components-overview/Color")));
// const Shadow = Loadable(lazy(() => import("pages/components-overview/Shadow")));
// const AntIcons = Loadable(
//   lazy(() => import("pages/components-overview/AntIcons"))
// );

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <LoginForm />,
    },
    // {
    //   path: "/login",
    //   element: <LoginForm />,
    // },
    {
      path: "/dashboard",
      element: <DashboardDefault />,
    },
    {
      path: "color",
      element: <Color />,
    },
    {
      path: "dashboard",
      children: [
        {
          path: "default",
          element: <DashboardDefault />,
        },
      ],
    },
    {
      path: "sample-page",
      element: <SamplePage />,
    },
    {
      path: "daily-report",
      element: <DailyReport />,
    },
    {
      path: "data-entry",
      element: <DataEntryForm />,
    },
    {
      path: "monthly-report",
      element: <MonthlyReport />,
    },

    {
      path: "yearly-report",
      element: <YearlyReport />,
    },

    {
      path: "yearly-report-console",
      element: <YearlyConsoleReport />,
    },
    {
      path: "performance-chart",
      element: <MyChart />,
    },
    {
      path: "upload",
      element: <UploadFile />,
    },
    // {
    //   path: "customer-create",
    //   element: <MasterReport />,
    // },
    {
      path: "customer-create",
      element: <MasterReport />,
    },
    {
      path: "customer-list",
      element: <MasterList />,
    },
    {
      path: "editMaster",
      element: <EditForm />,
    },

    //Turbine section
    {
      path: "turbine-create",
      element: <TurbineCreateForm />,
    },
    {
      path: "turbine-list",
      element: <TurbineList />,
    },
    {
      path: "turbine-edit",
      element: <TurbineEditForm />,
    },

    //Site section
    {
      path: "site-create",
      element: <SiteCreate />,
    },
    {
      path: "site-list",
      element: <SiteList />,
    },
    {
      path: "site-edit",
      element: <SiteEdit />,
    },
    {
      path: "location-create",
      element: <LocationCreate />,
    },
    {
      path: "location-list",
      element: <LocationList />,
    },
    {
      path: "location-edit",
      element: <LocatoinEdit />,
    },
    //Project master
    {
      path: "project-create",
      element: <ProjectMasterCreate />,
    },
    {
      path: "project-master",
      element: <ProjectMasterList />,
    },
    {
      path: "project-edit",
      element: <ProjectMasterEdit />,
    },
    //Turbine unit
    {
      path: "turbine-unit-create",
      element: <TurbineUnitCreate />,
    },
    {
      path: "turbine-unit",
      element: <TurbineUnitList />,
    },
    {
      path: "turbine-unit-edit",
      element: <TurbineUnitEdit />,
    },
    //Turbine Type
    {
      path: "turbine-type-create",
      element: <TurbineTypeCreate />,
    },
    {
      path: "turbine-type",
      element: <TurbineTypeList />,
    },
    {
      path: "turbine-type-edit",
      element: <TurbineTypeEdit />,
    },
    {
      path: "userList",
      element: <Userlist />,
    },
    {
      path: "changePwd",
      element: <ChangePassword />,
    },
    {
      path: "viewProfile",
      element: <ViewProfile />,
    },
  ],
};

export default MainRoutes;
