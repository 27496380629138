import React, { useEffect, useRef, useState } from "react";
import { Grid, makeStyles, Button } from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import { axiosConfig } from "config";
import axios from "../../../../node_modules/axios/index";
import Snackbar from "common/Snackbar";

const useStyle = makeStyles((theme) => ({
  padding: {
    paddingBottom: theme.spacing(4),
  },
  button: {
    width: "100%",
    background: "#3f51b5",
    color: "white",
    "&:hover": {
      backgroundColor: "#3f51b5",
    },
  },

  // cardWrapper: {
  //   display: "flex",
  //   flexDirection: "column",
  //   flexWrap: true
  // },
}));

//Data
const initialValues = {
  username: "",
  password: "",
};

//validation schema
let validationSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  password: Yup.string().required("Required!"),
});

const UserForm = () => {
  let navigate = useNavigate();
  const classes = useStyle();
  const captchaRef = useRef(null);
  const [open, setOpen] = React.useState({});
  const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const onSubmit = (values, { resetForm }) => {
    setLoading(true);
    const token = captchaRef.current.getValue(); // Token should be sent with values to the backend
    captchaRef.current.reset();
    // console.log({ values });
    // Do post call here
    axiosConfig
      .post("/auth/login", values)
      .then((res) => {
        const data = res.data;
        setOpen((prevState) => {
          return {
            ...prevState,
            open: true,
            message: res.data.message,
          };
        });
        if (data.status === true) {
          localStorage.setItem("token", data.token);
          localStorage.setItem("userData", data.data);
          localStorage.setItem("id", data.data.id);
          axios.defaults.headers.common["token"] = data.token;
          setLoading(false);
          navigate("/dashboard/default");
        }
      })
      .catch((err) => {
        resetForm(initialValues);
        setLoading(false);
        setOpen((prevState) => {
          return {
            ...prevState,
            open: true,
            message: "Error",
          };
        });
      });
    // const data = {
    //     "status": true,
    //   "token": "1|bicEHIZMx09S7gz0ONUiUcoE66Puy60QhoD26wDr",
    //   "message":"User Logged In Successfully"
    // }

    // return  <Navigate  to="/dashboard" replace />
  };

  const onCaptchaChange = (value) => {
    if (value) {
      setIsCaptchaChecked(true);
    }
  };

  return (
    <>
      <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ dirty, isValid, values, handleChange, handleBlur }) => {
          // console.log({ dirty, isValid, isCaptchaChecked });
          return (
            <Form>
              <Grid container spacing={3} className={classes.cardWrapper}>
                <Grid item xs={12} sm={12} md={12}>
                  <Field
                    label="Username"
                    variant="outlined"
                    fullWidth
                    name="username"
                    value={values.username}
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Field
                    label="Password"
                    variant="outlined"
                    fullWidth
                    name="password"
                    value={values.password}
                    type="password"
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={16}>
                  <ReCAPTCHA
                    sitekey={"6LcIR84hAAAAABh4jEZ_X-posypwjZzeAyH5j86Y"} // should be replaced with env variable
                    onChange={onCaptchaChange}
                    ref={captchaRef}
                    size={"normal"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  {/* <Button
                    disabled={!isValid || !isCaptchaChecked}
                    variant="contained"
                    color="primary"
                    size="large"
                    type="Submit"
                    className={classes.button}
                  >
                    Login
                  </Button> */}
                  <LoadingButton
                    loading={loading}
                    loadingIndicator="Loading…"
                    variant="outlined"
                    color="primary"
                    size="large"
                    type="Submit"
                    className={classes.button}
                  >
                    Login
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Snackbar open={open} setOpen={setOpen} />
    </>
  );
};

export default UserForm;
