import {
  ChromeOutlined,
  QuestionOutlined,
  UploadOutlined,
  FormOutlined,
  TableOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  UploadOutlined,
  FormOutlined,
  TableOutlined,
  UserSwitchOutlined,
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const userData = JSON.parse(localStorage.getItem("userData"));
const customerId =
  typeof userData?.customer_id == "undefined" ? "" : userData?.customer_id;
let master = {};
if (customerId != "") {
  master = {
    id: "master",
    title: "Master",
    type: "group",
    children: [
      {
        id: "master-list",
        title: "Customer",
        type: "item",
        url: "/customer-list",
        icon: icons.TableOutlined,
      },
      {
        id: "project-master",
        title: "Project Master",
        type: "item",
        url: "/project-master",
        icon: icons.TableOutlined,
      },
      // {
      //   id: "turbine-unit",
      //   title: "Turbine Unit",
      //   type: "item",
      //   url: "/turbine-unit",
      //   icon: icons.TableOutlined,
      // },
      // {
      //   id: "turbine-type",
      //   title: "Turbine type",
      //   type: "item",
      //   url: "/turbine-type",
      //   icon: icons.TableOutlined,
      // },
      // {
      //   id: "turbine-list",
      //   title: "Turbine",
      //   type: "item",
      //   url: "/turbine-list",
      //   icon: icons.TableOutlined,
      // },
      {
        id: "user-list",
        title: "User List",
        type: "item",
        url: "/userList",
        icon: icons.UserSwitchOutlined,
      },
      // {
      //   id: "site-list",
      //   title: "Site",
      //   type: "item",
      //   url: "/site-list",
      //   icon: icons.TableOutlined,
      // },
      // {
      //   id: "location-list",
      //   title: "Location",
      //   type: "item",
      //   url: "/location-list",
      //   icon: icons.TableOutlined,
      // },
    ],
  };
} else {
  master = {
    id: "master",
    title: "Master",
    type: "group",
    children: [
      {
        id: "master-list",
        title: "Customer",
        type: "item",
        url: "/customer-list",
        icon: icons.TableOutlined,
      },
      {
        id: "project-master",
        title: "Project master",
        type: "item",
        url: "/project-master",
        icon: icons.TableOutlined,
      },
      {
        id: "turbine-unit",
        title: "Turbine Unit",
        type: "item",
        url: "/turbine-unit",
        icon: icons.TableOutlined,
      },
      {
        id: "turbine-type",
        title: "Turbine Type",
        type: "item",
        url: "/turbine-type",
        icon: icons.TableOutlined,
      },
      {
        id: "turbine-list",
        title: "Turbine",
        type: "item",
        url: "/turbine-list",
        icon: icons.TableOutlined,
      },
      {
        id: "site-list",
        title: "Site",
        type: "item",
        url: "/site-list",
        icon: icons.TableOutlined,
      },
      {
        id: "location-list",
        title: "Location",
        type: "item",
        url: "/location-list",
        icon: icons.TableOutlined,
      },
      {
        id: "user-list",
        title: "User List",
        type: "item",
        url: "/userList",
        icon: icons.UserSwitchOutlined,
      },
    ],
  };
}

export default master;
