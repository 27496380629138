import React, { useState } from "react";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import axios from "../../../node_modules/axios/index";
import MessageModal from "common/MessageModal";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "../../../node_modules/react-router/index";
import Snackbar from "common/Snackbar";
import DoneIcon from "@mui/icons-material/Done";
import Divider from "@mui/material/Divider";

const useStyles = makeStyles((theme) => ({
  TextField: {
    width: "330px",
  },
  textField_label: {
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
  },
  hideLabel: {
    display: "none",
  },
  pwdContainer: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Poppins, sans-serif",
  },
  formBox: {
    boxShadow: " 0px 3px 15px rgb(0 0 0 / 10%)",
    borderRadius: "5px",
    display: "flex",
  },
  pwdRules: {
    display: "flex",
    flexDirection: "column",
    padding: "1.5rem",
    "& p": {
      fontWeight: "bold",
      fontSize: "14px",
    },
  },
  pwdConditions: {
    display: "flex",
    flexDirection: "column",
    "& span": {
      paddingBottom: "0.25rem",
    },
  },
}));

const UserChangePwd = ({ id, onClose, loadCustomer }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState({});
  const [formValue, setFormValue] = useState({
    new_password: "",
    confirm_password: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
  });

  React.useEffect(() => {}, []);

  const handleClose = () => {
    setOpen((prevState) => {
      return {
        open: false,
      };
    });
    setFormValue({ customer_status: "A", modified_by: "Araman" });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const goBack = () => {
    onClose(false);
  };
  const validateFields = (form) => {
    if (!form.new_password || !form.confirm_password) {
      setOpenSnackbar((prevState) => {
        return {
          ...prevState,
          open: true,
          message: "Fields cannot be empty",
          state: "error",
        };
      });
      return true;
    }

    if (
      form.new_password &&
      form.confirm_password &&
      form.new_password !== form.confirm_password
    ) {
      setOpenSnackbar((prevState) => {
        return {
          ...prevState,
          open: true,
          message: "New password and repeat new password do not match",
          state: "error",
        };
      });
      return true;
    }

    return false;
  };

  const API_URL = process.env.REACT_APP_BACKEND_API;
  const update = async () => {
    if (validateFields(formValue)) {
      return;
    } else {
      const userObj = JSON.parse(localStorage.getItem("userData"));

      const payload = {
        ...formValue,
        id: id,
        modified_by: userObj.customer_name,
      };
      axios.post(`${API_URL}changePasswordByAdmin`, payload).then((res) => {
        setOpenSnackbar((prevState) => {
          return {
            ...prevState,
            open: true,
            message: res.data.message,
          };
        });
      });
    }
  };

  return (
    <>
      <div className={classes.pwdContainer}>
        <MessageModal handleClose={handleClose} open={open} />
        <div>
          <div className="masterCrudForm">
            <TextField
              className={classes.TextField}
              label="New Password"
              variant="outlined"
              size="medium"
              id="new_password"
              type="password"
              value={formValue?.new_password || ""}
              name="new_password"
              onChange={handleChange}
              InputLabelProps={{
                shrink: formValue?.["new_password"] ? true : false,
                className: formValue?.["new_password"]
                  ? `${classes.textField_label}`
                  : undefined,
              }}
            />

            <TextField
              className={classes.TextField}
              label="Repeat new password"
              variant="outlined"
              size="medium"
              id="confirm_password"
              type="password"
              value={formValue?.confirm_password || ""}
              name="confirm_password"
              onChange={handleChange}
              InputLabelProps={{
                shrink: formValue?.["confirm_password"] ? true : false,
                className: formValue?.["confirm_password"]
                  ? `${classes.textField_label}`
                  : undefined,
              }}
            />
          </div>
          <div className="actionBtns crudBtnpadding">
            <Button
              variant="contained"
              onClick={update}
              style={{ margin: "auto 10px" }}
            >
              Update
            </Button>
            {/* <Button
                variant="contained"
                onClick={goBack}
                style={{ margin: "auto 10px" }}
              >
                Back
              </Button> */}
          </div>
        </div>
        <Divider
          orientation="vertical"
          flexItem
          style={{ borderColor: "#d7d2d2" }}
        />
        <div className={classes.pwdRules}>
          <p>New password must contain</p>
          <div className={classes.pwdConditions}>
            <span>
              {" "}
              <DoneIcon color="success" style={{ marginRight: "15px" }} /> At
              least 8 characters
            </span>
            <span>
              {" "}
              <DoneIcon color="success" style={{ marginRight: "15px" }} /> At
              least 1 lowercase letter(a-z)
            </span>
            <span>
              {" "}
              <DoneIcon color="success" style={{ marginRight: "15px" }} /> At
              least 1 uppercase letter(A-Z)
            </span>
            <span>
              {" "}
              <DoneIcon color="success" style={{ marginRight: "15px" }} /> At
              least 1 number(0-9)
            </span>
            <span>
              {" "}
              <DoneIcon color="success" style={{ marginRight: "15px" }} /> At
              least 1 special characters
            </span>
          </div>
        </div>

        <Snackbar open={openSnackbar} setOpen={setOpenSnackbar} />
      </div>
    </>
  );
};

export default UserChangePwd;
