import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";

export default function PopupComponent({
  open,
  onClose,
  children,
  title,
  width = "100%",
}) {
  const handleClose = () => {
    onClose(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={open}
        onClose={handleClose}
        // className={{paper:className}}
        PaperProps={{
          sx: {
            width: { width },
          },
        }}
      >
        <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
          <h5>{title}</h5>
          <span
            style={{
              cursor: "pointer",
              color: "blue",
              position: "absolute",
              right: "25px",
            }}
            onClick={handleClose}
          >
            <HighlightOffSharpIcon />
            {/* CLOSE{" "} */}
          </span>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
