import React, { useState } from "react";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import axios from "../../../node_modules/axios/index";
import MessageModal from "common/MessageModal";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "common/Snackbar";
const API_URL = process.env.REACT_APP_BACKEND_API;

const useStyles = makeStyles((theme) => ({
  TextField: {
    width: "100%",
  },
  textField_label: {
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
  },
  hideLabel: {
    display: "none",
  },
}));

const LocationCreateNew = ({ id, rowId, onClose, refreshData }) => {
  const classes = useStyles();

  const [open, setOpen] = useState({});
  const [error, setError] = useState(false);
  const [userData, setUserData] = useState({});
  const [formValueError, setFormValueError] = useState({});
  const [formValue, setFormValue] = useState({});
  const [customerList, setCustomerList] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
  });
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClose = () => {
    setOpen((prevState) => {
      return {
        open: false,
      };
    });
    setFormValue({ customer_status: "A", modified_by: "Arman" });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormValueError((prevState) => {
      return {
        ...prevState,
        [name]: value ? false : true,
      };
    });

    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const createOrUpdate = async () => {
    let msg = "";

    if (id) {
      const payload = {
        ...formValue,
        customer_status: "A",
        modified_by: userData?.customer_name,
      };
      await axios.put(`${API_URL}location/${id}`, payload).then((res) => {
        setError(res.data.error);
        if (res.data.error) {
          if (res?.data?.data?.location_name) {
            msg = res?.data?.data?.location_name[0];
          } else if (res?.data?.data?.country_id) {
            msg = res?.data?.data?.country_id[0];
          } else if (res?.data?.data?.modified_by) {
            msg = res?.data?.data?.modified_by[0];
          }
        } else {
          msg = res?.data.message;
        }

        setOpenSnackbar((prevState) => {
          return {
            ...prevState,
            open: true,
            message: msg,
          };
        });
        refreshData();
      });
    } else {
      let msg = "";
      const payload = {
        ...formValue,
        customer_status: "A",
        created_by: userData?.customer_name,
      };
      axios.post(`${API_URL}location`, payload).then((res) => {
        setError(res.data.error);
        if (res.data.error) {
          if (res?.data?.data?.location_name) {
            msg = res?.data?.data?.location_name[0];
          } else if (res?.data?.data?.country_id) {
            msg = res?.data?.data?.country_id[0];
          } else if (res?.data?.data?.created_by) {
            msg = res?.data?.data?.created_by[0];
          }
        } else {
          msg = res?.data.message;
          setFormValue({});
        }

        setOpenSnackbar((prevState) => {
          return {
            ...prevState,
            open: true,
            message: msg,
          };
        });
        refreshData();
      });
    }
  };

  const goBack = () => {
    onClose(false);
    rowId(null);
  };

  React.useEffect(() => {
    if (id) {
      axios.get(`${API_URL}location/${id}`).then((res) => {
        setFormValue(res.data.data);
      });
    }
    const userData = JSON.parse(localStorage.getItem("userData"));
    setUserData(userData);
  }, []);

  return (
    <>
      <MessageModal handleClose={goBack} open={open} />
      <div>
        <div className="masterCrudForm pt-2">
          <TextField
            className={classes.TextField}
            label={formValueError["location_name"] ? "Required" : "Location"}
            variant="outlined"
            size="medium"
            required
            id="location_name"
            type="text"
            name="location_name"
            value={formValue.location_name || ""}
            onChange={handleChange}
            error={formValueError["location_name"]}
            InputLabelProps={{
              shrink: formValue["location_name"] ? true : false,
              className: formValue["location_name"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />

          <TextField
            className={classes.TextField}
            label={formValueError["country_id"] ? "Required" : "Country"}
            error={formValueError["country_id"]}
            variant="outlined"
            size="medium"
            id="country_id"
            required
            select
            value={formValue?.country_id || ""}
            name="country_id"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue?.["country_id"] ? true : false,
              className: formValue?.["country_id"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          >
            <MenuItem key={"IN"} value={"IN"}>
              India
            </MenuItem>
          </TextField>
        </div>
        <div className="actionBtns crudBtnpadding">
          <Button
            variant="contained"
            onClick={createOrUpdate}
            style={{ margin: "auto 10px", width: "200px" }}
          >
            {id ? "Update" : "Submit"}
          </Button>
        </div>
      </div>
      <Snackbar open={openSnackbar} error={error} setOpen={setOpenSnackbar} />
    </>
  );
};

export default LocationCreateNew;
