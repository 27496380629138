import React, { useState } from "react";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import axios from "../../../node_modules/axios/index";
import MessageModal from "common/MessageModal";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "../../../node_modules/react-router/index";
import Snackbar from "common/Snackbar";
import { forEach } from "lodash";
const API_URL = process.env.REACT_APP_BACKEND_API;

const useStyles = makeStyles((theme) => ({
  TextField: {
    width: "100%",
  },
  textField_label: {
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
  },
  hideLabel: {
    display: "none",
  },
}));

const CustomerCreateEditModal = ({ id, rowId, onClose, refreshData, type }) => {
  const classes = useStyles();

  const [open, setOpen] = useState({});
  const [error, setError] = useState(false);
  const [userData, setUserData] = useState({});
  const [formValueError, setFormValueError] = useState({});
  const [formValue, setFormValue] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
  });
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClose = () => {
    setOpen((prevState) => {
      return {
        open: false,
      };
    });
    setFormValue({ modified_by: "Arman" });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormValueError((prevState) => {
      return {
        ...prevState,
        [name]: value ? false : true,
      };
    });

    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    setValues({ ...values, ["password"]: event.target.value });
  };

  const createOrUpdate = async () => {
    let msg = "";

    if (id) {
      const payload = {
        ...formValue,
        modified_by: userData?.customer_name,
      };
      await axios.put(`${API_URL}customer/${id}`, payload).then((res) => {
        setError(res.data.error);
        if (res.data.error) {
          Object.keys(res.data?.data).forEach((key) => {
            msg = msg + res.data?.data[key][0] + "\n";
          });
        } else {
          msg = res?.data.message;
        }

        setOpenSnackbar((prevState) => {
          return {
            ...prevState,
            open: true,
            message: msg,
          };
        });
        refreshData();
      });
    } else {
      let msg = "";
      const payload = {
        ...formValue,
        customer_status: "A",
        created_by: userData?.customer_name,
      };
      axios.post(`${API_URL}customer`, payload).then((res) => {
        setError(res.data.error);
        if (res.data.error) {
          Object.keys(res.data?.data).forEach((key) => {
            msg = msg + res.data?.data[key][0] + "\n";
          });
        } else {
          msg = res?.data.message;
          setFormValue({});
        }

        setOpenSnackbar((prevState) => {
          return {
            ...prevState,
            open: true,
            message: msg,
          };
        });
        refreshData();
      });
    }
  };

  const goBack = () => {
    onClose(false);
    rowId(null);
  };

  React.useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    setUserData(userData);
  }, []);
  React.useEffect(() => {
    if (id) {
      axios.get(`${API_URL}customer/${id}`).then((res) => {
        setFormValue(res.data.data);
      });
    }
  }, []);

  return (
    <>
      <MessageModal handleClose={goBack} open={open} />
      <div>
        <div className="customerForm">
          <TextField
            className={classes.TextField}
            label={
              formValueError["customer_name"] ? "Required" : "Customer Name"
            }
            error={formValueError["customer_name"]}
            value={formValue.customer_name || ""}
            required
            variant="outlined"
            size="medium"
            id="customer_name"
            type="text"
            InputProps={{
              readOnly: type == "view" ? true : false,
            }}
            name="customer_name"
            onChange={handleChange}
          />
          <TextField
            className={classes.TextField}
            label={
              formValueError["customer_reference"]
                ? "Required"
                : "Customer Reference"
            }
            error={formValueError["customer_reference"]}
            value={formValue.customer_reference || ""}
            required
            variant="outlined"
            size="medium"
            id="customer_reference"
            type="text"
            name="customer_reference"
            onChange={handleChange}
            InputProps={{
              readOnly: type == "view" ? true : false,
            }}
            InputLabelProps={{
              shrink: formValue["customer_reference"] ? true : false,
              className: formValue["customer_reference"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={
              formValueError["customer_email"] ? "Required" : "Customer Email"
            }
            error={formValueError["customer_email"]}
            value={formValue.customer_email || ""}
            variant="outlined"
            size="medium"
            id="customer_email"
            type="text"
            name="customer_email"
            onChange={handleChange}
            InputProps={{
              readOnly: type == "view" ? true : false,
            }}
            InputLabelProps={{
              shrink: formValue["customer_email"] ? true : false,
              className: formValue["customer_email"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={
              formValueError["customer_mobile"] ? "Required" : "Customer Mobile"
            }
            error={formValueError["customer_mobile"]}
            value={formValue.customer_mobile || ""}
            variant="outlined"
            size="medium"
            id="customer_mobile"
            type="text"
            name="customer_mobile"
            onChange={handleChange}
            InputProps={{
              readOnly: type == "view" ? true : false,
            }}
            InputLabelProps={{
              shrink: formValue["customer_mobile"] ? true : false,
              className: formValue["customer_mobile"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={
              formValueError["customer_phone"] ? "Required" : "Customer Phone"
            }
            error={formValueError["customer_phone"]}
            value={formValue.customer_mobile || ""}
            variant="outlined"
            size="medium"
            id="customer_phone"
            type="text"
            name="customer_phone"
            onChange={handleChange}
            InputProps={{
              readOnly: type == "view" ? true : false,
            }}
            InputLabelProps={{
              shrink: formValue["customer_phone"] ? true : false,
              className: formValue["customer_phone"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={
              formValueError["customer_city"] ? "Required" : "Customer City"
            }
            error={formValueError["customer_city"]}
            value={formValue.customer_city || ""}
            variant="outlined"
            size="medium"
            id="customer_city"
            type="text"
            name="customer_city"
            onChange={handleChange}
            InputProps={{
              readOnly: type == "view" ? true : false,
            }}
            InputLabelProps={{
              shrink: formValue["customer_city"] ? true : false,
              className: formValue["customer_city"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={
              formValueError["customer_address1"] ? "Required" : "Address1"
            }
            error={formValueError["customer_address1"]}
            value={formValue.customer_address1 || ""}
            variant="outlined"
            size="medium"
            id="customer_address1"
            type="text"
            name="customer_address1"
            onChange={handleChange}
            InputProps={{
              readOnly: type == "view" ? true : false,
            }}
            InputLabelProps={{
              shrink: formValue["customer_address1"] ? true : false,
              className: formValue["customer_address1"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={
              formValueError["customer_address2"] ? "Required" : "Address2"
            }
            error={formValueError["customer_address2"]}
            value={formValue.customer_address2 || ""}
            variant="outlined"
            size="medium"
            id="customer_address2"
            type="text"
            name="customer_address2"
            onChange={handleChange}
            InputProps={{
              readOnly: type == "view" ? true : false,
            }}
            InputLabelProps={{
              shrink: formValue["customer_address2"] ? true : false,
              className: formValue["customer_address2"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={
              formValueError["customer_address3"] ? "Required" : "Address3"
            }
            error={formValueError["customer_address3"]}
            value={formValue.customer_address3 || ""}
            variant="outlined"
            size="medium"
            id="customer_address3"
            type="text"
            name="customer_address3"
            onChange={handleChange}
            InputProps={{
              readOnly: type == "view" ? true : false,
            }}
            InputLabelProps={{
              shrink: formValue["customer_address3"] ? true : false,
              className: formValue["customer_address3"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={
              formValueError["customer_district"] ? "Required" : "District"
            }
            error={formValueError["customer_district"]}
            value={formValue.customer_district || ""}
            variant="outlined"
            size="medium"
            id="customer_district"
            type="text"
            name="customer_district"
            onChange={handleChange}
            InputProps={{
              readOnly: type == "view" ? true : false,
            }}
            InputLabelProps={{
              shrink: formValue["customer_district"] ? true : false,
              className: formValue["customer_district"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={formValueError["customer_pincode"] ? "Required" : "Pincode"}
            error={formValueError["customer_pincode"]}
            value={formValue.customer_pincode || ""}
            variant="outlined"
            size="medium"
            id="customer_pincode"
            type="text"
            name="customer_pincode"
            onChange={handleChange}
            InputProps={{
              readOnly: type == "view" ? true : false,
            }}
            InputLabelProps={{
              shrink: formValue["customer_pincode"] ? true : false,
              className: formValue["customer_pincode"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={formValueError["customer_state"] ? "Required" : "State"}
            error={formValueError["customer_state"]}
            value={formValue.customer_state || ""}
            variant="outlined"
            size="medium"
            id="customer_state"
            type="text"
            name="customer_state"
            onChange={handleChange}
            InputProps={{
              readOnly: type == "view" ? true : false,
            }}
            InputLabelProps={{
              shrink: formValue["customer_state"] ? true : false,
              className: formValue["customer_state"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={
              formValueError["site_address1"] ? "Required" : "Site Address1"
            }
            error={formValueError["site_address1"]}
            value={formValue.site_address1 || ""}
            variant="outlined"
            size="medium"
            id="site_address1"
            type="text"
            name="site_address1"
            onChange={handleChange}
            InputProps={{
              readOnly: type == "view" ? true : false,
            }}
            InputLabelProps={{
              shrink: formValue["site_address1"] ? true : false,
              className: formValue["site_address1"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={
              formValueError["site_address2"] ? "Required" : "Site Address2"
            }
            error={formValueError["site_address2"]}
            value={formValue.site_address2 || ""}
            variant="outlined"
            size="medium"
            id="site_address2"
            type="text"
            name="site_address2"
            onChange={handleChange}
            InputProps={{
              readOnly: type == "view" ? true : false,
            }}
            InputLabelProps={{
              shrink: formValue["site_address2"] ? true : false,
              className: formValue["site_address2"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={
              formValueError["site_address3"] ? "Required" : "Site Address3"
            }
            error={formValueError["site_address3"]}
            value={formValue.site_address3 || ""}
            variant="outlined"
            size="medium"
            id="site_address3"
            type="text"
            name="site_address3"
            onChange={handleChange}
            InputProps={{
              readOnly: type == "view" ? true : false,
            }}
            InputLabelProps={{
              shrink: formValue["site_address3"] ? true : false,
              className: formValue["site_address3"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={formValueError["site_city"] ? "Required" : "Site City"}
            error={formValueError["site_city"]}
            value={formValue.site_city || ""}
            variant="outlined"
            size="medium"
            id="site_city"
            type="text"
            name="site_city"
            onChange={handleChange}
            InputProps={{
              readOnly: type == "view" ? true : false,
            }}
            InputLabelProps={{
              shrink: formValue["site_city"] ? true : false,
              className: formValue["site_city"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={
              formValueError["site_district"] ? "Required" : "Site District"
            }
            error={formValueError["site_district"]}
            value={formValue.site_district || ""}
            variant="outlined"
            size="medium"
            id="site_district"
            type="text"
            name="site_district"
            onChange={handleChange}
            InputProps={{
              readOnly: type == "view" ? true : false,
            }}
            InputLabelProps={{
              shrink: formValue["site_district"] ? true : false,
              className: formValue["site_district"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={formValueError["site_pincode"] ? "Required" : "Site Pincode"}
            error={formValueError["site_pincode"]}
            value={formValue.site_pincode || ""}
            variant="outlined"
            size="medium"
            id="site_pincode"
            type="text"
            name="site_pincode"
            onChange={handleChange}
            InputProps={{
              readOnly: type == "view" ? true : false,
            }}
            InputLabelProps={{
              shrink: formValue["site_pincode"] ? true : false,
              className: formValue["site_pincode"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={formValueError["site_state"] ? "Required" : "Site State"}
            error={formValueError["site_state"]}
            value={formValue.site_state || ""}
            variant="outlined"
            size="medium"
            id="site_state"
            type="text"
            name="site_state"
            onChange={handleChange}
            InputProps={{
              readOnly: type == "view" ? true : false,
            }}
            InputLabelProps={{
              shrink: formValue["site_state"] ? true : false,
              className: formValue["site_state"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={formValueError["customer_status"] ? "Required" : "Status"}
            error={formValueError["customer_status"]}
            variant="outlined"
            size="medium"
            id="customer_status"
            required
            select
            value={formValue?.customer_status || ""}
            name="customer_status"
            onChange={handleChange}
            InputProps={{
              readOnly: type == "view" ? true : false,
            }}
            InputLabelProps={{
              shrink: formValue?.["customer_status"] ? true : false,
              className: formValue?.["customer_status"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          >
            <MenuItem key={"A"} value={"A"}>
              Activate
            </MenuItem>
            <MenuItem key={"D"} value={"D"}>
              De-Activate
            </MenuItem>
          </TextField>
        </div>
        {type != "view" && (
          <div className="actionBtns crudBtnpadding">
            <Button
              variant="contained"
              onClick={createOrUpdate}
              style={{ margin: "auto 10px", width: "200px" }}
            >
              {id ? "Update" : "Submit"}
            </Button>
          </div>
        )}
      </div>
      <Snackbar open={openSnackbar} error={error} setOpen={setOpenSnackbar} />
    </>
  );
};

export default CustomerCreateEditModal;
