import {
  UploadOutlined,
  FormOutlined 
} from "@ant-design/icons";

// icons
const icons = {
  UploadOutlined,
  FormOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const DataGeneration = {
  id: "data-generation",
  title: "Data Generation",
  type: "group",
  children: [
    {
      id: "upload",
      title: "Upload",
      type: "item",
      url: "/upload",
      icon: icons.UploadOutlined,
    },
    {
      id: "dataEntry",
      title: "Data Entry",
      type: "item",
      url: "/data-entry",
      icon: icons.FormOutlined
    },
    // {
    //   id: "master",
    //   title: "",
    //   type: "item",
    //   url: "/editMaster",
    // },
  ],
};

export default DataGeneration;
