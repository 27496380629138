// material-ui
import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Divider from "@mui/material/Divider";
import axios from "../../../node_modules/axios/index";
import Loader from "components/Loader";
import TextField from "@mui/material/TextField";
import "./DailyReport.css";
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";

const YearlyConsoleReport = () => {
  const API_URL = process.env.REACT_APP_BACKEND_API;
  const autoCompleteRef = React.useRef(null);
  const [rows, setRows] = React.useState([]);
  const [project, setProject] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [projectHelperText, setProjectHelperText] = React.useState("");

  const wholeState = useSelector((state) => state.reportReducer);
  const projectRows = wholeState.projects;

  const reset = (event) => {
    setRows([]);
  };
  const fetchQuery = () => {
    if (project == "") {
      setProjectHelperText("Project is required");
    } else {
      setLoader(true);

      axios
        .get(`${API_URL}getYearlyConsole/${project}`)
        .then((res) => {
          setRows(res.data.data);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const clearReport = () => {
    setRows([]);
  };
  const printReport = () => {
    var content = document.getElementById("printContent");
    var mywindow = window.open("", "PRINT", "height=400,width=600");

    mywindow.document.write(
      "<html><head><title>" + document.title + "</title>"
    );
    mywindow.document.write("</head><body >");
    mywindow.document.write("<h1>" + document.title + "</h1>");
    mywindow.document.write(document.getElementById("printContent").innerHTML);
    mywindow.document.write("</body></html>");

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
    // var content = document.getElementById("printContent");
    // var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    // pri.document.open();
    // pri.document.write(content.innerHTML);
    // pri.document.close();
    // pri.focus();
    // pri.print();
  };

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `yearly_console_report`,
    sheet: `${rows?.projectData?.customer_name}`,
  });

  return (
    <div className="container">
      {loader && <Loader />}
      <Card elevation={3}>
        <CardContent>
          <div className="row">
            <div className="col-4">
              <FormControl fullWidth>
                <Autocomplete
                  onChange={(event, value) => setProject(value?.project_id)}
                  id="Project"
                  options={projectRows || {}}
                  getOptionLabel={(option) =>
                    option.project_name + "(" + option.project_ref_id + ")"
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.project_id}>
                        {option.project_name +
                          " (" +
                          option.project_ref_id +
                          ")"}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Project"
                      size="small"
                      error={projectHelperText != "" ? true : false}
                      helperText={projectHelperText}
                    />
                  )}
                  ref={autoCompleteRef}
                />
              </FormControl>
            </div>
            <div className="col-6">
              <Button
                variant="contained"
                onClick={fetchQuery}
                style={{ margin: "auto 10px" }}
              >
                Query
              </Button>

              <Button
                variant="contained"
                style={{ margin: "auto 10px" }}
                onClick={clearReport}
              >
                Clear
              </Button>
            </div>
          </div>
          {/* <div className="row mt-2">
            <div className="col d-flex justify-content-center">
              <Button
                variant="contained"
                onClick={fetchQuery}
                style={{ margin: "auto 10px" }}
              >
                Query
              </Button>
              <Button
                variant="contained"
                style={{ margin: "auto 10px" }}
                onClick={clearReport}
              >
                Clear
              </Button>
            </div>
          </div> */}
        </CardContent>
      </Card>
      <Divider />
      {rows?.console?.length > 0 ? (
        <div className="card  bg-light m-auto mt-3 w-100">
          <div className="card-body">
            <div className="row">
              <div className="col" id="printContent" ref={tableRef}>
                {/* <TableContainer component={Paper}> */}
                <Table
                  className="tableInfoHeader"
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" colSpan={4}>
                        <h6 style={{ marginTop: "0.5rem", fontWeight: "600" }}>
                          VESTAS WIND TECHNOLOGY INDIA PRIVATE LIMITED
                        </h6>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" colSpan={4}>
                        <h6>GENERATION REPORT - Yearly Console</h6>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">
                        Customer Name : {rows?.projectData?.customer_name}
                      </TableCell>
                      <TableCell align="center">
                        Project Name: {rows?.projectData?.project_name}
                      </TableCell>
                      <TableCell align="center">
                        Capacity: {rows?.projectData?.total_turbines} *{" "}
                        {parseFloat(rows?.projectData?.turbine_output)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        Site:
                        {rows?.projectData?.site_name}
                      </TableCell>
                      <TableCell align="center">
                        WEGs Commissioned On:{" "}
                        {rows?.projectData.date_of_commissioning}
                        {/* {rows?.projectData?.date_of_commissioning
                          ? moment(rows?.date_of_commissioning).format(
                              "DD-MM-YYYY"
                            )
                          : "-"} */}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table
                  className="mt-3"
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="Yearly Report table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Month</TableCell>
                      <TableCell align="center">LCS(Actuals)</TableCell>
                      <TableCell align="center">EB Export(kwh)</TableCell>
                      <TableCell align="center">Machine Avail(%)</TableCell>
                      <TableCell align="center">Grid Avail(%)</TableCell>
                      <TableCell align="center">Plant Factor</TableCell>
                      <TableCell align="center">Internal Grid Avail</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.console?.map((row, index) => {
                      let lcsTotal = 0;
                      let ebTotal = 0;
                      let machineTotal = 0;
                      let gridTotal = 0;
                      let pfTotal = 0;
                      let intGridTotal = 0;

                      return (
                        <>
                          {row?.data?.map((r, rIndex) => {
                            lcsTotal = Number(lcsTotal) + Number(r.lcs);
                            ebTotal = Number(ebTotal) + Number(r.EBExpKwh);

                            machineTotal =
                              Number(machineTotal) + Number(r.macAvail);
                            gridTotal = Number(gridTotal) + Number(r.gridAvail);
                            pfTotal = Number(pfTotal) + Number(r.PLF);
                            intGridTotal =
                              Number(intGridTotal) + Number(r.IntGrid);

                            return (
                              <TableRow key={`${index}_${rIndex}`}>
                                <TableCell
                                  align="center"
                                  style={{ whiteSpace: "nowrap" }}
                                  key={`${index}_${rIndex}_1`}
                                >
                                  {r.formated_date}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  key={`${index}_${rIndex}_2`}
                                >
                                  {Number(r.lcs)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  key={`${index}_${rIndex}_3`}
                                >
                                  {Number(r.EBExpKwh)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  key={`${index}_${rIndex}_4`}
                                >
                                  {Number(r.macAvail).toFixed(2)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  key={`${index}_${rIndex}_5`}
                                >
                                  {Number(r.gridAvail).toFixed(2)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  key={`${index}_${rIndex}_6`}
                                >
                                  {Number(r.PLF).toFixed(2)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  key={`${index}_${rIndex}_7`}
                                >
                                  {row?.IntGrid ? row?.IntGrid : "-"}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          <TableRow key={index}>
                            <TableCell
                              align="center"
                              style={{
                                fontWeight: "bold",
                                background: "lightgrey",
                              }}
                              key={`${index}_1`}
                            >
                              Total
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontWeight: "bold",
                                background: "lightgrey",
                              }}
                              key={`${index}_2`}
                            >
                              {Number(row?.lcs)}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontWeight: "bold",
                                background: "lightgrey",
                              }}
                              key={`${index}_3`}
                            >
                              {Number(ebTotal)}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontWeight: "bold",
                                background: "lightgrey",
                              }}
                              key={`${index}_4`}
                            >
                              {Number(row?.macAvail).toFixed(2)}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontWeight: "bold",
                                background: "lightgrey",
                              }}
                              key={`${index}_5`}
                            >
                              {Number(row?.gridAvail).toFixed(2)}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontWeight: "bold",
                                background: "lightgrey",
                              }}
                              key={`${index}_6`}
                            >
                              {Number(row?.PLF).toFixed(2)}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontWeight: "bold",
                                background: "lightgrey",
                              }}
                              key={`${index}_7`}
                            >
                              {Number(row?.IntGrid).toFixed(2)}
                            </TableCell>
                            {/* <TableCell
                              align="center"
                              style={{
                                fontWeight: "bold",
                                background: "lightgrey",
                              }}
                            >
                              {Number(row?.estLcs).toFixed(2)}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontWeight: "bold",
                                background: "lightgrey",
                              }}
                            >
                              {Number(row?.toAchieveLcs).toFixed(2)}
                            </TableCell> */}
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <Table
                className="mt-3"
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="Yearly Report table"
              >
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      {/* <input
                        type="button"
                        className="btn btn-primary"
                        onClick={printReport}
                        id="Print"
                        value="Print"
                      /> */}
                      <Button
                        variant="contained"
                        style={{ margin: "auto 10px" }}
                        onClick={onDownload}
                      >
                        Export
                      </Button>
                      <input
                        type="button"
                        className="btn btn-primary"
                        id="Cancel"
                        onClick={reset}
                        value="Cancel"
                      />
                    </TableCell>
                    {/* <TableCell align="left"></TableCell> */}
                  </TableRow>
                </TableBody>
              </Table>
            </div>{" "}
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default YearlyConsoleReport;
export function consoleTableRow() {
  return (
    <TableRow>
      <TableCell align="center">1</TableCell>
      <TableCell align="center">3(Actuals)</TableCell>
      <TableCell align="center">34 Export(kwh)</TableCell>
      <TableCell align="center">Machine Avail(%)</TableCell>
      <TableCell align="center">Grid Avail(%)</TableCell>
      <TableCell align="center">Plant Factor</TableCell>
      <TableCell align="center">Internal Grid Avail</TableCell>
    </TableRow>
  );
}
