import React, { useState } from "react";
import {
  Button,
} from "@mui/material";
import "../formmaster.css";
import TextField from '@mui/material/TextField';
import axios from "../../../node_modules/axios/index";
import MessageModal from "common/MessageModal";
import { useNavigate } from "../../../node_modules/react-router-dom/index";
import { makeStyles } from '@material-ui/core/styles';
import { styled } from "@mui/system";
import Snackbar from "common/Snackbar";
import Autocomplete from "@mui/material/Autocomplete";
const StyledTextField = styled(TextField, {
  name: "StyledTextField",
})({
  width: 300,
  "& .MuiInputBase-root": {
      "& .MuiInputLabel-shrink, & fieldset": {
        fontSize: "24px"
      }
    }
});

const useStyles = makeStyles(
  theme => ({
    TextField:{
      width: "330px",
    },
    textField_label: {
      fontSize: "16px",
      fontFamily: 'Poppins, sans-serif',
    },
    hideLabel : {
        display:"none"
    }
  }))

const MasterReport = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const [open, setOpen] = useState({});
  const [locationres, setLocation] = useState([]);
  const [formValue, setFormValue] = useState({
    customer_status: "A",
    created_by: "Araman",
  });
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: ""
  })
  const handleClose = () => {
    setOpen((prevState) => {
      return {
        open: false,
      };
    });
    setFormValue({ customer_status: "A", created_by: "Araman" });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const API_URL = process.env.REACT_APP_BACKEND_API;
  React.useEffect(()=>{
    axios.get(`${API_URL}location`).then(res => {
        const datares = res.data.data;
        datares.forEach(function (arrayItem) {
          var x = arrayItem.location_name;
          arrayItem.label = arrayItem.location_name;
        });
        setLocation(datares)
      
    })
  },[])
  const create = () => {
    axios.post(`${API_URL}siteMaster`, formValue).then((res) => {
    //   setOpen((prevState) => {
    //     return {
    //       open: true,
    //       content: res.data.message,
    //       title: res.data.code,
    //     };
    //   });
    setOpenSnackbar((prevState) => {
        return {
            ...prevState,
            open: true,
            message:" Site created Successfully"
        }
    })
    });
  };
  const goBack = () => {
    navigate("/site-list")
}
  return (
    <>
      <MessageModal handleClose={handleClose} open={open} />
      <div className="createCustomerWrapper">
          <div className="masterCrudForm">
                <TextField  
                className={classes.TextField} 
                label="Site Id"
                variant="outlined" 
                size="medium"
                id="site_id"
                type="text"
                value={formValue.site_id || ""}
                name="site_id"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: formValue["site_id"] ? true : false,
                  className : formValue["site_id"] ? `${classes.textField_label}` : undefined,
                }}
               />
                {/* <TextField  
                className={classes.TextField} 
                label="Location Id"
                variant="outlined" 
                size="medium"
                id="location_id"
                type="text"
                value={formValue.location_id || ""}
                name="location_id"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: formValue["location_id"] ? true : false,
                  className : formValue["location_id"] ? `${classes.textField_label}` : undefined,
                }}
               /> */}
               <Autocomplete
               onChange={(event, value) => setFormValue((prevState) => {
                return {
                  ...prevState,
                  "location_id": value.location_id,
                };
              })}
                id="LocationId"
                options={locationres}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Location"
                    size="small"
                    //error={projectHelperText != "" ? true : false}
                   // helperText={projectHelperText}
                  />
                )}
              />
                <TextField  
                className={classes.TextField} 
                label="Site Name"
                variant="outlined" 
                size="medium"
                id="site_name"
                type="text"
                value={formValue.site_name || ""}
                name="site_name"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: formValue["site_name"] ? true : false,
                  className : formValue["site_name"] ? `${classes.textField_label}` : undefined,
                }}
               />
                <TextField  
                className={classes.TextField} 
                label="Headoffice"
                variant="outlined" 
                size="medium"
                id="headoffice_yn"
                type="text"
                value={formValue.headoffice_yn || ""}
                name="headoffice_yn"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: formValue["headoffice_yn"] ? true : false,
                  className : formValue["headoffice_yn"] ? `${classes.textField_label}` : undefined,
                }}
               />
          </div>
          <div className="actionBtns crudBtnpadding">
          <Button
            variant="contained"
            onClick={create}
            style={{ margin: "auto 10px",  width: "200px"  }}
            >
            Create
        </Button>
        <Button
            variant="contained"
            onClick={goBack}
            style={{ margin: "auto 10px", width: "200px" }}
            >
            Back
        </Button>
          </div>
         </div>
         <Snackbar open={openSnackbar} setOpen={setOpenSnackbar} />
    </>
  );
};
export default MasterReport;
