import React, { useState } from "react";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import axios from "../../../node_modules/axios/index";
import MessageModal from "common/MessageModal";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "../../../node_modules/react-router/index";
import Snackbar from "common/Snackbar";
import DoneIcon from "@mui/icons-material/Done";
import Divider from "@mui/material/Divider";

const useStyles = makeStyles((theme) => ({
  TextField: {
    width: "330px",
  },
  textField_label: {
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
  },
  hideLabel: {
    display: "none",
  },
  pwdContainer: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Poppins, sans-serif",
    marginTop: "3rem",
  },
  formBox: {
    boxShadow: " 0px 3px 15px rgb(0 0 0 / 10%)",
    borderRadius: "5px",
    display: "flex",
  },
  pwdRules: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "2rem",
    "& p": {
      fontWeight: "bold",
      fontSize: "16px",
      paddingBottom: "1.5rem",
    },
  },
  pwdConditions: {
    display: "flex",
    flexDirection: "column",
    "& span": {
      paddingBottom: "1.5rem",
    },
  },
}));

const ChangePassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState({});
  const [formValue, setFormValue] = useState({
    oldPwd: "",
    newPwd1: "",
    newPwd2: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
  });

  React.useEffect(() => {}, []);

  const handleClose = () => {
    setOpen((prevState) => {
      return {
        open: false,
      };
    });
    setFormValue({ customer_status: "A", modified_by: "Araman" });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const validateFields = (form) => {
    if (!form.oldPwd || !form.newPwd1 || !form.newPwd2) {
      setOpenSnackbar((prevState) => {
        return {
          ...prevState,
          open: true,
          message: "Fields cannot be empty",
          state: "error",
          error: true,
        };
      });
      return true;
    }

    if (form.newPwd1 && form.newPwd2 && form.newPwd1 !== form.newPwd2) {
      setOpenSnackbar((prevState) => {
        return {
          ...prevState,
          open: true,
          message: "New password and repeat new password do not match",
          state: "error",
          error: true,
        };
      });
      return true;
    }

    if (form.oldPwd && form.oldPwd === form.newPwd1) {
      setOpenSnackbar((prevState) => {
        return {
          ...prevState,
          open: true,
          message: "New password and current password match",
          state: "error",
          error: true,
        };
      });
      return true;
    }
    return false;
  };

  const API_URL = process.env.REACT_APP_BACKEND_API;
  const update = async () => {
    if (validateFields(formValue)) {
      return;
    } else {
      const userObj = JSON.parse(localStorage.getItem("userData"));

      const payload = {
        old_password: formValue?.oldPwd,
        new_password: formValue?.newPwd1,
        confirm_password: formValue?.newPwd2,
        id: userObj.user_id,
        modified_by: userObj.customer_name,
      };
      axios.post(`${API_URL}changePassword`, payload).then((res) => {
         if (!res?.data?.error) {
          setOpenSnackbar((prevState) => {
            return {
              ...prevState,
              open: true,
              message: res?.data?.message,
              error: false,
            };
          });
        } else {
          setOpenSnackbar((prevState) => {
            return {
              ...prevState,
              open: true,
              message: res?.data?.message,
              state: "error",
              error: true,
            };
          });
        }
      });
    }
  };

  const goBack = () => {
    navigate("/dashboard/default");
  };
  return (
    <>
      <div className={classes.pwdContainer}>
        <MessageModal handleClose={handleClose} open={open} />
        <div className={classes.formBox}>
          <div>
            <div className="masterCrudForm">
              <h3>Change Password</h3>
              <TextField
                className={classes.TextField}
                label="Old Password"
                variant="outlined"
                size="medium"
                id="oldPwd"
                type="password"
                value={formValue?.oldPwd || ""}
                name="oldPwd"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: formValue?.["oldPwd"] ? true : false,
                  className: formValue?.["oldPwd"]
                    ? `${classes.textField_label}`
                    : undefined,
                }}
              />
              <TextField
                className={classes.TextField}
                label="New Password"
                variant="outlined"
                size="medium"
                id="newPwd1"
                type="password"
                value={formValue?.newPwd1 || ""}
                name="newPwd1"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: formValue?.["newPwd1"] ? true : false,
                  className: formValue?.["newPwd1"]
                    ? `${classes.textField_label}`
                    : undefined,
                }}
              />

              <TextField
                className={classes.TextField}
                label="Repeat new password"
                variant="outlined"
                size="medium"
                id="newPwd2"
                type="password"
                value={formValue?.newPwd2 || ""}
                name="newPwd2"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: formValue?.["newPwd2"] ? true : false,
                  className: formValue?.["newPwd2"]
                    ? `${classes.textField_label}`
                    : undefined,
                }}
              />
            </div>
            <div className="actionBtns crudBtnpadding">
              <Button
                variant="contained"
                onClick={update}
                style={{ margin: "auto 10px" }}
              >
                Update
              </Button>
              <Button
                variant="contained"
                onClick={goBack}
                style={{ margin: "auto 10px" }}
              >
                Back
              </Button>
            </div>
          </div>
          <Divider
            orientation="vertical"
            flexItem
            style={{ borderColor: "#d7d2d2" }}
          />
          <div className={classes.pwdRules}>
            <p>New password must contain</p>
            <div className={classes.pwdConditions}>
              <span>
                {" "}
                <DoneIcon color="success" style={{ marginRight: "15px" }} /> At
                least 8 characters
              </span>
              <span>
                {" "}
                <DoneIcon color="success" style={{ marginRight: "15px" }} /> At
                least 1 lowercase letter(a-z)
              </span>
              <span>
                {" "}
                <DoneIcon color="success" style={{ marginRight: "15px" }} /> At
                least 1 uppercase letter(A-Z)
              </span>
              <span>
                {" "}
                <DoneIcon color="success" style={{ marginRight: "15px" }} /> At
                least 1 number(0-9)
              </span>
              <span>
                {" "}
                <DoneIcon color="success" style={{ marginRight: "15px" }} /> At
                least 1 special characters
              </span>
            </div>
          </div>
        </div>
        <Snackbar
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          error={openSnackbar?.error}
        />
      </div>
    </>
  );
};

export default ChangePassword;
