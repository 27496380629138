import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MinimalLayout from "layout/MinimalLayout/index";

// render - login
const UserLogin = Loadable(
  lazy(() => import("pages/authentication/auth-forms/Login-new"))
);

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: <MinimalLayout />,

  children: [
    {
      path: "/",
      element: <UserLogin />,
    },
  ],
};

export default LoginRoutes;
