import React, { useState } from "react";
import { Button } from "@mui/material";
import "../formmaster.css";
import TextField from "@mui/material/TextField";
import axios from "../../../node_modules/axios/index";
import MessageModal from "common/MessageModal";
import { useNavigate } from "../../../node_modules/react-router-dom/index";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "../../../node_modules/react-router/index";
import Snackbar from "common/Snackbar";

const useStyles = makeStyles((theme) => ({
  TextField: {
    width: "330px",
  },
  textField_label: {
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
  },
  hideLabel: {
    display: "none",
  },
}));

const TurbineUnitEdit = () => {
  const classes = useStyles();
  const location = useLocation();

  const id = location.state.id;
  const navigate = useNavigate();
  const [open, setOpen] = useState({});
  const [formValue, setFormValue] = useState({
    customer_status: "A",
    created_by: "Araman",
  });
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
  });

  React.useEffect(() => {
    axios.get(`${API_URL}turbineUnitMaster/${id}`).then((res) => {
      setFormValue(res.data.data);
    });
  }, []);

  const handleClose = () => {
    setOpen((prevState) => {
      return {
        open: false,
      };
    });
    setFormValue({ customer_status: "A", modified_by: "Araman" });
    navigate("/turbine-unit");
  };
  // const [formValue, setFormValue] = useState({customer_status:"A",created_by:"Araman"})
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
        ...{ customer_status: "A", modified_by: "Araman" },
      };
    });
  };

  const API_URL = process.env.REACT_APP_BACKEND_API;
  const update = async () => {
    await axios.put(`${API_URL}turbine/${id}`, formValue).then((res) => {
      setOpenSnackbar((prevState) => {
        return {
          ...prevState,
          open: true,
          message: " Turbine details updated Successfully",
        };
      });
    });
  };

  const goBack = () => {
    navigate("/turbine-unit-list");
  };
  return (
    <>
      <MessageModal handleClose={handleClose} open={open} />
      <div className="createCustomerWrapper">
        <div className="masterCrudForm">
          <TextField
            className={classes.TextField}
            label="Turbine unit Id"
            variant="outlined"
            size="medium"
            id="turbine_unit_id"
            type="text"
            value={formValue.turbine_unit_id || ""}
            name="turbine_unit_id"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["turbine_unit_id"] ? true : false,
              className: formValue["turbine_unit_id"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label="Turbine Unit"
            variant="outlined"
            size="medium"
            id="turbine_unit"
            type="text"
            value={formValue.turbine_unit || ""}
            name="turbine_unit"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["turbine_unit"] ? true : false,
              className: formValue["turbine_unit"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label="Mult Factor to kwh"
            variant="outlined"
            size="medium"
            id="mult_factor_to_kwh"
            type="text"
            value={formValue.mult_factor_to_kwh || ""}
            name="mult_factor_to_kwh"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["mult_factor_to_kwh"] ? true : false,
              className: formValue["mult_factor_to_kwh"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
        </div>
        <div className="actionBtns crudBtnpadding">
          <Button
            variant="contained"
            onClick={update}
            style={{ margin: "auto 10px", width: "200px" }}
          >
            Update
          </Button>
          <Button
            variant="contained"
            onClick={goBack}
            style={{ margin: "auto 10px", width: "200px" }}
          >
            Back
          </Button>
        </div>
      </div>
      <Snackbar open={openSnackbar} setOpen={setOpenSnackbar} />
    </>
  );
};

export default TurbineUnitEdit;
