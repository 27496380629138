// assets
import {
  ChromeOutlined,
  QuestionOutlined,
  CopyFilled,
  DashboardTwoTone,
  CalendarFilled,
  SlidersFilled,
} from "@ant-design/icons";

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  CopyFilled,
  DashboardTwoTone,
  CalendarFilled,
  SlidersFilled,
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const support = {
  id: "report",
  title: "Report",
  type: "group",
  children: [
    {
      id: "daily-report",
      title: "Daily Report",
      type: "item",
      url: "/daily-report",
      icon: icons.DashboardTwoTone,
    },
    {
      id: "monthly-report",
      title: "Monthly Report",
      type: "item",
      url: "/monthly-report",
      icon: icons.CalendarFilled,
    },
    {
      id: "yearly-report",
      title: "Yearly Report",
      type: "item",
      url: "/yearly-report",
      icon: icons.CopyFilled,
    },
    {
      id: "yearly-report-console",
      title: "Yearly Console Report",
      type: "item",
      url: "/yearly-report-console",
      icon: icons.CopyFilled,
    },
    // {
    //   id: "performance-chart",
    //   title: "Performance Chart",
    //   type: "item",
    //   url: "/performance-chart",
    //   icon: icons.SlidersFilled,
    // },
  ],
};

export default support;
