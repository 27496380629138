import React, { useState } from "react";
import { Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import axios from "../../../node_modules/axios/index";
import MessageModal from "common/MessageModal";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "common/Snackbar";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles((theme) => ({
  TextField: {
    width: "100%",
  },
  textField_label: {
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
  },
  hideLabel: {
    display: "none",
  },
}));
const handleCustomerData = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const customerId =
    typeof userData?.customer_id == "undefined" ? "" : userData?.customer_id;
  const userType = userData?.user_type;
  const userId = userData?.user_id;
  return { customerId, userType, userId };
};

const EditForm = ({ id, rowId, onClose, loadCustomer }) => {
  let { customerId, userType, userId } = handleCustomerData();

  const classes = useStyles();
  const passwordRef = React.useRef(null);

  const [open, setOpen] = useState({});
  const [customerList, setCustomerList] = useState([]);
  const [formValueError, setFormValueError] = useState({});
  const [formValue, setFormValue] = useState({});
  const [userData, setUserData] = useState({});

  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
  });
  const [error, setError] = useState(false);
  const [password, setPassword] = useState("");
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  React.useEffect(() => {
    axios.get(`${API_URL}userMaster/${id}`).then((res) => {
      setFormValue(res.data.data);
    });
    const userData = JSON.parse(localStorage.getItem("userData"));
    setUserData(userData);
  }, []);

  const handleClose = () => {
    setOpen((prevState) => {
      return {
        open: false,
      };
    });
    setFormValue({
      modified_by: userData?.customer_name,
    });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValueError((prevState) => {
      return {
        ...prevState,
        [name]: value ? false : true,
      };
    });
    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
        ...{ modified_by: userData?.customer_name },
      };
    });
    if (event.target.name == "password") {
      setValues({ ...values, ["password"]: event.target.value });
      setPassword(event.target.value);
    }
  };

  const API_URL = process.env.REACT_APP_BACKEND_API;
  const update = async () => {
    let msg = "";

    if (id) {
      const payload = {
        ...formValue,
        modified_by: "Admin",
      };

      await axios.put(`${API_URL}userMaster/${id}`, payload).then((res) => {
        setError(res.data.error);
        if (res.data.error) {
          Object.keys(res.data?.data).forEach((key) => {
            console.log(res.data?.data[key][0]);
            msg = msg + res.data?.data[key][0] + "\n";
          });
        } else {
          msg = res?.data.message;
        }
        setOpenSnackbar((prevState) => {
          return {
            ...prevState,
            open: true,
            message: msg,
          };
        });
        loadCustomer();
      });
    } else {
      let payload = {
        ...formValue,
        customer_status: "A",
        created_by: "Admin",
      };
      if (customerId) {
        const cusPayload = {
          ...payload,
          customer_name: customerId,
          user_type: "CP",
        };
        payload = cusPayload;
      }
      await axios.post(`${API_URL}userMaster`, payload).then((res) => {
        setError(res.data.error);
        if (res.data.error) {
          Object.keys(res.data?.data).forEach((key) => {
            msg = msg + res.data?.data[key][0] + "\n";
          });
        } else {
          msg = res?.data.message;
          setPassword("");
          setFormValue({});
        }
        setOpenSnackbar((prevState) => {
          return {
            ...prevState,
            open: true,
            message: msg,
          };
        });
        loadCustomer();
      });
    }
  };

  const goBack = () => {
    onClose(false);
    rowId(null);
    setPassword("");
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  React.useEffect(() => {
    axios.get(`${API_URL}getActiveCustomer`).then((res) => {
      setCustomerList(res.data.data);
    });
  }, []);
  const userTypeOptions = [
    { label: "Super Admin", value: "SA" },
    { label: "Service Admin", value: "AD" },
    { label: "Service Engineer", value: "SE" },
    { label: "Marketing", value: "MR" },
    { label: "Customer - Main", value: "CM" },
    { label: "Customer - Personnel", value: "CP" },
    { label: "Stock User", value: "CSU" },
  ];
  return (
    <>
      <MessageModal handleClose={goBack} open={open} />
      <div>
        <div className="masterCrudForm">
          <TextField
            fullWidth
            className={classes.TextField}
            label={formValueError["user_name"] ? "Required" : "User name"}
            error={formValueError["user_name"]}
            variant="outlined"
            size="medium"
            id="user_name"
            type="text"
            value={formValue?.user_name || ""}
            name="user_name"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue?.["user_name"] ? true : false,
              className: formValue?.["user_name"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          {customerId ? (
            <TextField
              fullWidth
              className={classes.TextField}
              label={formValueError["customer_name"] ? "Required" : "Customer"}
              error={formValueError["customer_name"]}
              variant="outlined"
              size="medium"
              id="customer_name"
              select
              required
              value={customerId || ""}
              name="customer_name"
              onChange={handleChange}
              InputProps={{
                readOnly: true,
              }}
            >
              {customerList?.map((option) => (
                <MenuItem key={option.customer_id} value={option.customer_id}>
                  {option.customer_name}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <TextField
              fullWidth
              className={classes.TextField}
              label={formValueError["customer_name"] ? "Required" : "Customer"}
              error={formValueError["customer_name"]}
              variant="outlined"
              size="medium"
              id="customer_name"
              select
              required
              value={formValue?.customer_name || ""}
              name="customer_name"
              onChange={handleChange}
            >
              {customerList?.map((option) => (
                <MenuItem key={option.customer_id} value={option.customer_id}>
                  {option.customer_name}
                </MenuItem>
              ))}
            </TextField>
          )}
          {userType == "CM" ? (
            <TextField
              fullWidth
              className={classes.TextField}
              label={formValueError["user_type"] ? "Required" : "User Type"}
              error={formValueError["user_type"]}
              variant="outlined"
              size="medium"
              id="user_type"
              select
              value={"CP"}
              name="user_type"
              onChange={handleChange}
            >
              <MenuItem key={"CP"} value={"CP"}>
                {"Customer-Personal"}
              </MenuItem>
            </TextField>
          ) : (
            <TextField
              fullWidth
              className={classes.TextField}
              label={formValueError["user_type"] ? "Required" : "User Type"}
              error={formValueError["user_type"]}
              variant="outlined"
              size="medium"
              id="user_type"
              select
              value={formValue?.user_type || ""}
              name="user_type"
              onChange={handleChange}
              InputLabelProps={{
                shrink: formValue?.["user_type"] ? true : false,
                className: formValue?.["user_type"]
                  ? `${classes.textField_label}`
                  : undefined,
              }}
            >
              {userTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}

          <TextField
            fullWidth
            className={classes.TextField}
            label={formValueError["user_status"] ? "Required" : "Status"}
            error={formValueError["user_status"]}
            variant="outlined"
            size="medium"
            id="user_status"
            select
            value={formValue?.user_status || ""}
            name="user_status"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue?.["user_status"] ? true : false,
              className: formValue?.["user_status"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          >
            <MenuItem key={"A"} value={"A"}>
              {"Activated"}
            </MenuItem>
            <MenuItem key={"D"} value={"D"}>
              {"Deactivated"}
            </MenuItem>
          </TextField>
          {!id && (
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                name="password"
                onChange={handleChange}
                value={password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            // <TextField
            //   className={classes.TextField}
            //   label={formValueError["password"] ? "Required" : "Password"}
            //   error={formValueError["password"]}
            //   variant="outlined"
            //   size="medium"
            //   value={formValue?.password || ""}
            //   id="password"
            //   type="password"
            //   name="password"
            //   onChange={handleChange}
            //   InputLabelProps={{
            //     shrink: formValue?.["password"] ? true : false,
            //     className: formValue?.["password"]
            //       ? `${classes.textField_label}`
            //       : undefined,
            //   }}
            // />
          )}
        </div>
        <div className="actionBtns crudBtnpadding">
          <Button
            variant="contained"
            onClick={update}
            style={{ margin: "auto 10px", width: "200px" }}
          >
            {id ? "Update" : "Submit"}
          </Button>
          {/* <Button
            variant="contained"
            onClick={goBack}
            style={{ margin: "auto 10px", width: "200px" }}
          >
            Back
          </Button> */}
        </div>
      </div>
      <Snackbar open={openSnackbar} error={error} setOpen={setOpenSnackbar} />
    </>
  );
};

export default EditForm;
