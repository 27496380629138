import React, { useState } from "react";
import {
  Button,
} from "@mui/material";
import "../formmaster.css";
import TextField from '@mui/material/TextField';
import axios from "../../../node_modules/axios/index";
import MessageModal from "common/MessageModal";
import { useNavigate } from "../../../node_modules/react-router-dom/index";
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from "../../../node_modules/react-router/index";
import Snackbar from "common/Snackbar";

const useStyles = makeStyles(
  theme => ({
    TextField:{
      width: "330px",
    },
    textField_label: {
      fontSize: "16px",
      fontFamily: 'Poppins, sans-serif',
    },
    hideLabel : {
        display:"none"
    }
  }))

const TurbineTypeEdit = () => {
  const classes = useStyles();
  const location = useLocation();

  const id = location.state.id;
  const navigate = useNavigate();
  const [open, setOpen] = useState({});
  const [formValue, setFormValue] = useState({
    customer_status: "A",
    created_by: "Araman",
  });
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: ""
  })

  React.useEffect(()=>{
    axios.get(`${API_URL}turbine/${id}`).then(res => {

        setFormValue(res.data.data)
        
    })
},[])

  const handleClose = () =>{
    setOpen((prevState)=>{
        return {
            open:false,
        }
    })
    setFormValue({customer_status:"A",modified_by:"Araman",})
    navigate('/turbine-list');
}
  // const [formValue, setFormValue] = useState({customer_status:"A",created_by:"Araman"})
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue((prevState) => {
        return {
            ...prevState,
            [name]: value,
           ...{customer_status:"A",modified_by:"Araman"}
        }
    })
}

  const API_URL = process.env.REACT_APP_BACKEND_API;
  const update = async() => {
    await axios.put(`${API_URL}TurbineTypeMaster/${id}`, formValue).then(res => {

      
        setOpenSnackbar((prevState) => {
            return {
                ...prevState,
                open: true,
                message:" Turbine details updated Successfully"
            }
        })
    })
}

  const goBack = () => {
    navigate("/turbine-type")
}
  return (
    <>
      <MessageModal handleClose={handleClose} open={open} />
      <div className="createCustomerWrapper">
          <div className="masterCrudForm">
          <TextField  
                className={classes.TextField} 
                label="Turbine Type Id"
                variant="outlined" 
                size="medium"
                id="turbine_type_id"
                type="text"
                value={formValue.turbine_type_id || ""}
                name="turbine_type_id"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: formValue["turbine_type_id"] ? true : false,
                  className : formValue["turbine_type_id"] ? `${classes.textField_label}` : undefined,
                }}
               />
                <TextField  
                className={classes.TextField} 
                label="Turbine Type"
                variant="outlined" 
                size="medium"
                id="turbine_type"
                type="text"
                value={formValue.turbine_type || ""}
                name="turbine_type"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: formValue["turbine_type"] ? true : false,
                  className : formValue["turbine_type"] ? `${classes.textField_label}` : undefined,
                }}
               />
                <TextField  
                className={classes.TextField} 
                label="Turbine Output"
                variant="outlined" 
                size="medium"
                id="turbine_output"
                type="text"
                value={formValue.turbine_output || ""}
                name="turbine_output"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: formValue["turbine_output"] ? true : false,
                  className : formValue["turbine_output"] ? `${classes.textField_label}` : undefined,
                }}
               />
                <TextField  
                className={classes.TextField} 
                label="Output unit Id"
                variant="outlined" 
                size="medium"
                id="output_unit_id"
                type="text"
                value={formValue.output_unit_id || ""}
                name="output_unit_id"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: formValue["output_unit_id"] ? true : false,
                  className : formValue["output_unit_id"] ? `${classes.textField_label}` : undefined,
                }}
               />
          </div>
          <div className="actionBtns crudBtnpadding">
          <Button
            variant="contained"
            onClick={update}
            style={{ margin: "auto 10px",  width: "200px"  }}
            >
            Update
        </Button>
        <Button
            variant="contained"
            onClick={goBack}
            style={{ margin: "auto 10px", width: "200px" }}
            >
            Back
        </Button>
          </div>
         </div>
         <Snackbar open={openSnackbar} setOpen={setOpenSnackbar} />

    </>
  );
};

export default TurbineTypeEdit