// data: [
//   {
//     // Change type to "doughnut", "line", "splineArea", etc.
//     type: "column",
//     dataPoints: [
//       { label: "2021", y: 100 },
//       { label: "2022", y: 200 },
//     ],
//   },
// ],

import React, { useState, useEffect } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import axios from "../../../node_modules/axios/index";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import "./DailyReport.css";
import MenuItem from "@mui/material/MenuItem";
import Loader from "components/Loader";

import ReactApexChart from "react-apexcharts";

const { REACT_APP_BACKEND_API } = process.env;

const data = [
  {
    name: "Apr",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "May",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Jun",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Jul",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Aug",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Sep",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Oct",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Nov",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Dec",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Jan",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Feb",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Mar",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
const parseDate = (val) => {
  const dateParse = new Date(val);
  const prarsedDate =
    dateParse.getFullYear() +
    "-" +
    (dateParse.getMonth() + 1) +
    "-" +
    dateParse.getDate();
  return prarsedDate;
};

const intialState = {
  fromDate: parseDate(new Date()),
  toDate: parseDate(new Date()),
  project_id: "",
  turbine_id: "",
  radioValue: "Power production",
};

export default function MyChart() {
  const autoCompleteRef = React.useRef(null);

  const [reportState, setReportState] = useState({ ...intialState });
  const [turbineList, setTurbineList] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [loader, setLoader] = useState(false);
  // const [fromDate, setFromDate] = React.useState(new Date());
  // const [toDate, setToDate] = React.useState(new Date());
  const [projectHelperText, setProjectHelperText] = React.useState("");
  const [turbineHelperText, setTurbineHelperText] = React.useState("");
  const [series, setSeries] = useState([]);
  const [chartData, setChartData] = useState([]);

  const wholeState = useSelector((state) => state.reportReducer);
  const projectRows = wholeState.projects;

  const extractValues = (arr, key) => {
    return arr.reduce((acc, data) => {
      acc.push(data[key]);
      return acc;
    }, []);
  };

  const fetchQuery = () => {
    if (reportState.project_id == "") {
      setProjectHelperText("Project is required");
    } else if (reportState.turbine_id == "") {
      setTurbineHelperText("Turbine is required");
    } else {
      setLoader(true);
      axios
        .post(`${REACT_APP_BACKEND_API}getMonthlyPerformanceChart`, reportState)
        .then((res) => {
          let response = res.data.data;
          if (response) {
            // if (reportState.radioValue == "Power production") {
            switch (reportState.radioValue) {
              case "Power production":
                setChartData([
                  {
                    name: "Power production",
                    data: response.lcs,
                  },
                ]);
                break;
              case "Grid Availablity(%)":
                setChartData([
                  {
                    name: "Grid Availablity(%)",
                    data: response.grid_avail,
                  },
                ]);
                break;
              case "Machine Availablity(%)":
                setChartData([
                  {
                    name: "Machine Availablity(%)",
                    data: response.machine_avail,
                  },
                ]);
                break;
              case "Internal Grid Availablity(%)":
                setChartData([
                  {
                    name: "Internal Grid Availablity(%)",
                    data: response.int_gridavail,
                  },
                ]);
                break;
              case "Reactive power import(%)":
                setChartData([
                  {
                    name: "Reactive power import(%)",
                    data: response.kvarh_import,
                  },
                ]);
                break;

              default:
                break;
            }
            // setChartData([
            //   {
            //     name: "Power production",
            //     // data: extractValues(response, "lcs"),
            //     data: response.lcs,
            //   },
            //   {
            //     name: "Machine Availablity(%)",
            //     // data: extractValues(response, "machine_avail"),
            //     data: response.machine_avail,
            //   },
            //   {
            //     name: "Grid Availablity(%)",
            //     // data: extractValues(response, "grid_avail"),
            //     data: response.grid_avail,
            //   },
            //   {
            //     name: "Internal Grid Availablity(%)",
            //     // data: extractValues(response, "int_gridavail"),
            //     data: response.int_gridavail,
            //   },
            //   {
            //     name: "Reactive power import(%)",
            //     // data: extractValues(response, "kvarh_import"),
            //     data: response.kvarh_import,
            //   },
            // ]);
            setLoader(false);
            // }
          }
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };
  React.useEffect(() => {
    if (chartData.length > 0) {
      setSeries(chartData);
    }
  }, [chartData]);

  const fetchTurbineId = (pid) => {
    if (pid == "") {
      setProjectHelperText("Project is required");
    } else {
      axios
        .get(`${REACT_APP_BACKEND_API}getAllActiveTurbineList/${pid}`)
        .then((res) => {
          setTurbineList(res.data.data);
          setProjectId(pid);
        });
    }
  };
  const clearReport = () => {
    setReportState({ ...intialState });
    setSeries(chartData);
    if (reportState !== "") {
      autoCompleteRef.current.querySelector('button').click()
    }
    setReportState((prevState) => {
      return {
        ...prevState,
        turbine_id: "", 
        project_id: ""
      };
    });
  };

  const columnChartOptions = {
    chart: {
      type: "bar",
      height: 430,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "10%",
        borderRadius: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 25,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
        "Jan",
        "Feb",
        "Mar",
      ],
    },
    yaxis: {
      forceNiceScale: false,
      max: 100,
      labels: {
        formatter: (value) => value.toFixed(0) +'%',
      },
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val}`;
        },
      },
    },
    legend: {
      show: true,
      fontFamily: `'Public Sans', sans-serif`,
      offsetX: 10,
      offsetY: 10,
      labels: {
        useSeriesColors: false,
      },
      markers: {
        width: 16,
        height: 16,
        radius: "50%",
        offsexX: 2,
        offsexY: 2,
      },
      itemMargin: {
        horizontal: 15,
        vertical: 50,
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          yaxis: {
            show: false,
          },
        },
      },
    ],
  };

  const handleRadio = (val) => {
    if (series) {
      let res = chartData.find((o) => o.name === val);
      setSeries([res]);
    }
    setReportState((prevState) => {
      return {
        ...prevState,
        radioValue: val,

      };
    });
  };
  return (
    <>
      <div className="row chartFormWrapper">
        <div className="col-8">
          <div className="row">
            <div className="col-6">
              <FormControl fullWidth>
                <Autocomplete
                  onChange={(event, value) => {
                    fetchTurbineId(value?.project_id);
                    setReportState((prevState) => {
                      return {
                        ...prevState,
                        project_id: value?.project_ref_id,
                      };
                    });
                  }}
                  id="customer"
                  options={projectRows}
                  getOptionLabel={(option) => option.project_name}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.project_ref_id}>
                        {option.project_name}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Project"
                      size="small"
                      error={projectHelperText != "" ? true : false}
                      helperText={projectHelperText}
                      // error={projectHelperText != "" ? true : false}
                      // helperText={projectHelperText}
                    />
                  )}
                  ref={autoCompleteRef}
                />
              </FormControl>
            </div>
            <div className="col-6">
              <FormControl fullWidth>
                <TextField
                  id="turbine-id"
                  select
                  label="Turbine."
                  size="small"
                  fullWidth
                  // value={reportState}
                  error={turbineHelperText != "" ? true : false}
                  helperText={turbineHelperText}
                  onChange={(event, value) => {
                    setReportState((prevState) => {
                      return {
                        ...prevState,
                        turbine_id: event.target.value,
                      };
                    });
                  }}
                  // helperText="Select your Service No."
                >
                  {turbineList.map((option) => (
                    <MenuItem key={option.turbine_id} value={option.turbine_id}>
                      {option.location_no +
                        " ( " +
                        option.turbine_reference +
                        " )"}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-6">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={["month", "year"]}
                  label="From"
                  value={reportState.fromDate}
                  onChange={(newValue) => {
                    // setFromDate(newValue);
                    setReportState((prevState) => {
                      return {
                        ...prevState,
                        fromDate: parseDate(newValue),
                      };
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} fullWidth />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-6">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={["month", "year"]}
                  label="To"
                  value={reportState.toDate}
                  onChange={(newValue) => {
                    setReportState((prevState) => {
                      return {
                        ...prevState,
                        toDate: parseDate(newValue),
                      };
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} fullWidth />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col d-flex justify-content-center">
              <Button
                variant="contained"
                onClick={fetchQuery}
                style={{ margin: "auto 10px" }}
              >
                View Report
              </Button>
              <Button
                variant="contained"
                style={{ margin: "auto 10px" }}
                onClick={clearReport}
              >
                Clear
              </Button>
            </div>
          </div>
        </div>
        <div className="col-4">
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={reportState.radioValue}
              onChange={(event, value) => {
                handleRadio(event.target.value);
              }}
            >
              <FormControlLabel
                value="Power production"
                control={<Radio size="small" />}
                label="Power production"
              />
              <FormControlLabel
                value="Machine Availablity(%)"
                control={<Radio size="small" />}
                label="Machine Availablity(%)"
              />
              <FormControlLabel
                value="Grid Availablity(%)"
                control={<Radio size="small" />}
                label="Grid Availablity(%)"
              />
              <FormControlLabel
                value="Internal Grid Availablity(%)"
                control={<Radio size="small" />}
                label="Internal Grid Availablity(%)"
              />
              <FormControlLabel
                value="Reactive power import(%)"
                control={<Radio size="small" />}
                label="Reactive Power Import(%)"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      {/* <ResponsiveContainer width="100%" height="50%" className="chartContainer">
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {/* <Bar dataKey="pv" stackId="a" fill="#ffc658" /> */}
      {/* <Bar dataKey="amt" stackId="a" fill="#82ca9d" /> */}
      {/* <Bar dataKey="uv" fill="#ffc658" /> */}

      {/* <Bar dataKey="pv" fill="#8884d8" barSize={10}/>
           <Bar dataKey="uv" fill="#82ca9d" barSize={10}/>

        </BarChart> */}
      {/* </ResponsiveContainer> */}
      {loader && <Loader />}
      {chartData.length > 0 && (
        <div className="chartContainer">
          <ReactApexChart
            options={columnChartOptions}
            series={series}
            type="bar"
            height={420}
          />
        </div>
      )}
    </>
  );
}
