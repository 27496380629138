import React, { useState } from "react";
import { Button } from "@mui/material";
import "../formmaster.css";
import TextField from "@mui/material/TextField";
import axios from "../../../node_modules/axios/index";
import MessageModal from "common/MessageModal";
import { useNavigate } from "../../../node_modules/react-router-dom/index";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "../../../node_modules/react-router/index";
import Snackbar from "common/Snackbar";

const useStyles = makeStyles((theme) => ({
  TextField: {
    width: "330px",
    // border: "1px solid #e2e2e25e",
    // borderRadius: theme.shape.borderRadius
  },
  textField_label: {
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
  },
  hideLabel: {
    display: "none",
  },
}));

const EditForm = ({ rowId, disabled = false }) => {
  const classes = useStyles();
  const location = useLocation();

  const id = location?.state?.id || rowId;
  const navigate = useNavigate();
  const [open, setOpen] = useState({});
  const [formValue, setFormValue] = useState({
    customer_status: "A",
    created_by: "Araman",
  });
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
  });

  React.useEffect(() => {
    axios.get(`${API_URL}customer/${id}`).then((res) => {
      setFormValue(res.data.data);
    });
  }, []);

  const handleClose = () => {
    setOpen((prevState) => {
      return {
        open: false,
      };
    });
    setFormValue({ customer_status: "A", modified_by: "Araman" });
    navigate("/masterlist");
  };
  // const [formValue, setFormValue] = useState({customer_status:"A",created_by:"Araman"})
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
        ...{ customer_status: "A", modified_by: "Araman" },
      };
    });
  };

  const API_URL = process.env.REACT_APP_BACKEND_API;
  const update = async () => {
    await axios.put(`${API_URL}customer/${id}`, formValue).then((res) => {
      console.log("Res==>", res.data);
      // setOpen((prevState)=>{
      //     return {
      //         open:true,
      //         content:res.data.message,
      //         title:res.data.code,
      //     }
      // })
      setOpenSnackbar((prevState) => {
        return {
          ...prevState,
          open: true,
          message: " Customer details updated Successfully",
        };
      });
    });
  };

  const goBack = () => {
    navigate("/customer-list");
  };
  return (
    <>
      <MessageModal handleClose={handleClose} open={open} />
      <div className={`createCustomerWrapper ${disabled} ? viewMode : ''}`}>
        <div className="masterCrudForm">
          <TextField
            className={classes.TextField}
            label="Customer Name"
            variant="outlined"
            size="medium"
            id="customer_name"
            type="text"
            value={formValue.customer_name || ""}
            name="customer_name"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["customer_name"] ? true : false,
              className: formValue["customer_name"]
                ? `${classes.textField_label}`
                : undefined,
            }}
            inputProps={
              { readOnly: disabled }
            }
          />
          <TextField
            className={classes.TextField}
            label="Customer Reference"
            variant="outlined"
            size="medium"
            id="customer_reference"
            type="text"
            value={formValue.customer_reference || ""}
            name="customer_reference"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["customer_reference"] ? true : false,
              className: formValue["customer_reference"]
                ? `${classes.textField_label}`
                : undefined,
            }}
            inputProps={
              { readOnly: disabled }
            }
          />
          <TextField
            className={classes.TextField}
            label="Customer Email"
            variant="outlined"
            size="medium"
            id="customer_email"
            type="text"
            value={formValue.customer_email || ""}
            name="customer_email"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["customer_email"] ? true : false,
              className: formValue["customer_email"]
                ? `${classes.textField_label}`
                : undefined,
            }}
            inputProps={
              { readOnly: disabled }
            }
          />
          <TextField
            className={classes.TextField}
            label="Customer Mobile"
            variant="outlined"
            size="medium"
            id="customer_mobile"
            type="text"
            value={formValue.customer_mobile || ""}
            name="customer_mobile"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["customer_mobile"] ? true : false,
              className: formValue["customer_mobile"]
                ? `${classes.textField_label}`
                : undefined,
            }}
            inputProps={
              { readOnly: disabled }
            }
          />
          <TextField
            className={classes.TextField}
            label="Customer Phone"
            variant="outlined"
            size="medium"
            id="customer_phone"
            type="text"
            value={formValue.customer_phone || ""}
            name="customer_phone"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["customer_phone"] ? true : false,
              className: formValue["customer_phone"]
                ? `${classes.textField_label}`
                : undefined,
            }}
            inputProps={
              { readOnly: disabled }
            }
          />
          <TextField
            className={classes.TextField}
            label="Customer City"
            variant="outlined"
            size="medium"
            id="customer_city"
            type="text"
            value={formValue.customer_city || ""}
            name="customer_city"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["customer_city"] ? true : false,
              className: formValue["customer_city"]
                ? `${classes.textField_label}`
                : undefined,
            }}
            inputProps={
              { readOnly: disabled }
            }
          />
          <TextField
            className={classes.TextField}
            label="Address1"
            variant="outlined"
            size="medium"
            id="customer_address1"
            type="text"
            value={formValue.customer_address1 || ""}
            name="customer_address1"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["customer_address1"] ? true : false,
              className: formValue["customer_address1"]
                ? `${classes.textField_label}`
                : undefined,
            }}
            inputProps={
              { readOnly: disabled }
            }
          />
          <TextField
            className={classes.TextField}
            label="Address2"
            variant="outlined"
            size="medium"
            id="customer_address2"
            type="text"
            value={formValue.customer_address2 || ""}
            name="customer_address2"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["customer_address2"] ? true : false,
              className: formValue["customer_address2"]
                ? `${classes.textField_label}`
                : undefined,
            }}
            inputProps={
              { readOnly: disabled }
            }
          />
          <TextField
            className={classes.TextField}
            label="Address3"
            variant="outlined"
            size="medium"
            id="customer_address3"
            type="text"
            value={formValue.customer_address3 || ""}
            name="customer_address3"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["customer_address3"] ? true : false,
              className: formValue["customer_address3"]
                ? `${classes.textField_label}`
                : undefined,
            }}
            inputProps={
              { readOnly: disabled }
            }
          />
          <TextField
            className={classes.TextField}
            label="District"
            variant="outlined"
            size="medium"
            id="customer_district"
            type="text"
            value={formValue.customer_district || ""}
            name="customer_district"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["customer_district"] ? true : false,
              className: formValue["customer_district"]
                ? `${classes.textField_label}`
                : undefined,
            }}
            inputProps={
              { readOnly: disabled }
            }
          />
          <TextField
            className={classes.TextField}
            label="Pincode"
            variant="outlined"
            size="medium"
            id="customer_pincode"
            type="text"
            value={formValue.customer_pincode || ""}
            name="customer_pincode"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["customer_pincode"] ? true : false,
              className: formValue["customer_pincode"]
                ? `${classes.textField_label}`
                : undefined,
            }}
            inputProps={
              { readOnly: disabled }
            }
          />
          <TextField
            className={classes.TextField}
            label="State"
            variant="outlined"
            size="medium"
            id="customer_state"
            type="text"
            value={formValue.customer_state || ""}
            name="customer_state"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["customer_state"] ? true : false,
              className: formValue["customer_state"]
                ? `${classes.textField_label}`
                : undefined,
            }}
            inputProps={
              { readOnly: disabled }
            }
          />
          <TextField
            className={classes.TextField}
            label="Site Address1"
            variant="outlined"
            size="medium"
            id="site_address1"
            type="text"
            value={formValue.site_address1 || ""}
            name="site_address1"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["site_address1"] ? true : false,
              className: formValue["site_address1"]
                ? `${classes.textField_label}`
                : undefined,
            }}
            inputProps={
              { readOnly: disabled }
            }
          />
          <TextField
            className={classes.TextField}
            label="Site Address2"
            variant="outlined"
            size="medium"
            id="site_address2"
            type="text"
            value={formValue.site_address2 || ""}
            name="site_address2"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["site_address2"] ? true : false,
              className: formValue["site_address2"]
                ? `${classes.textField_label}`
                : undefined,
            }}
            inputProps={
              { readOnly: disabled }
            }
          />
          <TextField
            className={classes.TextField}
            label="Site Address3"
            variant="outlined"
            size="medium"
            id="site_address3"
            type="text"
            value={formValue.site_address3 || ""}
            name="site_address3"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["site_address3"] ? true : false,
              className: formValue["site_address3"]
                ? `${classes.textField_label}`
                : undefined,
            }}
            inputProps={
              { readOnly: disabled }
            }
          />
          <TextField
            className={classes.TextField}
            label="Site City"
            variant="outlined"
            size="medium"
            id="site_city"
            type="text"
            value={formValue.site_city || ""}
            name="site_city"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["site_city"] ? true : false,
              className: formValue["site_city"]
                ? `${classes.textField_label}`
                : undefined,
            }}
            inputProps={
              { readOnly: disabled }
            }
          />
          <TextField
            className={classes.TextField}
            label="Site District"
            variant="outlined"
            size="medium"
            id="site_district"
            type="text"
            value={formValue.site_district || ""}
            name="site_district"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["site_district"] ? true : false,
              className: formValue["site_district"]
                ? `${classes.textField_label}`
                : undefined,
            }}
            inputProps={
              { readOnly: disabled }
            }
          />
          <TextField
            className={classes.TextField}
            label="Site Pincode"
            variant="outlined"
            size="medium"
            id="site_pincode"
            type="text"
            value={formValue.site_pincode || ""}
            name="site_pincode"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["site_pincode"] ? true : false,
              className: formValue["site_pincode"]
                ? `${classes.textField_label}`
                : undefined,
            }}
            inputProps={
              { readOnly: disabled }
            }
          />
          <TextField
            className={classes.TextField}
            label="Site State"
            variant="outlined"
            size="medium"
            id="site_state"
            type="text"
            value={formValue.site_state || ""}
            name="site_state"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["site_state"] ? true : false,
              className: formValue["site_state"]
                ? `${classes.textField_label}`
                : undefined,
            }}
            inputProps={
              { readOnly: disabled }
            }
          />
        </div>
        {!disabled && (
          <div className="actionBtns crudBtnpadding">
            <Button
              variant="contained"
              onClick={update}
              style={{ margin: "auto 10px", width: "200px" }}
            >
              Update
            </Button>
            <Button
              variant="contained"
              onClick={goBack}
              style={{ margin: "auto 10px", width: "200px" }}
            >
              Back
            </Button>
          </div>
        )}
      </div>
      <Snackbar open={openSnackbar} setOpen={setOpenSnackbar} />
    </>
  );
};
export default EditForm;
