import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {useNavigate} from "react-router-dom";
// material-ui
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import { EditOutlined, ProfileOutlined, LogoutOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons';
import axios from '../../../../../../node_modules/axios/index';
import { axiosConfig } from 'config';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ handleLogout }) => {
    const theme = useTheme();
    const API_URL = process.env.REACT_APP_BACKEND_API;
    const navigate = useNavigate();
    const [userData, setUserData] = useState({})
    const [selectedIndex, setSelectedIndex] = useState(0);
    useEffect(()=>{
        let userObj = localStorage.getItem("userData");
        setUserData(userObj)
    },[])

    const handleListItemClick = (event, index) => {
        if(index === 1){
            navigate("/viewProfile")
        } 
        if(index === 3){
            navigate("/changePwd")
        }
        setSelectedIndex(index);
    };


    return (
        <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
            
            {/* <ListItemButton selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0)}>
                <ListItemIcon>
                    <EditOutlined />
                </ListItemIcon>
                <ListItemText primary="Edit Profile" />
            </ListItemButton> */}
            {userData !== "null" && <ListItemButton selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)}>
                <ListItemIcon>
                    <UserOutlined />
                </ListItemIcon>
                <ListItemText primary="View Profile" />
            </ListItemButton>}
            {/* <ListItemButton selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2)}>
                <ListItemIcon>
                    <UserOutlined />
                </ListItemIcon>
                <ListItemText primary="User List" />
            </ListItemButton> */}
            <ListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3)}>
                <ListItemIcon>
                    <EditOutlined />
                </ListItemIcon>
                <ListItemText primary="Change Password" />
            </ListItemButton>

          
           
            <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
                <ListItemIcon>
                    <LogoutOutlined />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItemButton>
        </List>
    );
};

ProfileTab.propTypes = {
    handleLogout: PropTypes.func
};

export default ProfileTab;
