import React, { useState, useEffect,useMemo } from "react";
import axios from "../../../node_modules/axios/index";

import { useSelector } from "react-redux";

import Button from "@mui/material/Button";
import MUIDataTable from "mui-datatables";
import MessageModal from "common/MessageModal";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import { useNavigate } from "../../../node_modules/react-router-dom/index";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "common/Snackbar";
import Chip from "@mui/material/Chip";
import PopupComponent from "../../common/popup";
import EditForm from "../user-management/userList-edit";
import Switch from "@mui/material/Switch";
import Loader from "components/Loader";
import ChangePassword from "./changePwd";
import UserChangePwd from "./UserChangePwd";
import PasswordIcon from '@mui/icons-material/Password';

const API_URL = process.env.REACT_APP_BACKEND_API;
const handleCustomerData = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const customerId =
    typeof userData?.customer_id == "undefined" ? "" : userData?.customer_id;
    const userType =userData?.user_type;
    const userId =userData?.user_id;
  return {customerId, userType, userId};
};
export default function Userlist() {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      margin: "auto",
      position: "relative",
      " & .MuiPaper-root": {
        boxShadow: "0px 3px 15px rgb(0 0 0 / 10%)",
        borderRadius: "8px",
        flex: "1",
      },
    },
    columnHeader: {
      "& span": {
        "& Button": {
          fontWeight: "600",
          fontFamily: "Poppins, sans-serif",
        },
      },
    },
    arrangeCenter: {
      textAlign: "center",
    },
    centeredHeader: {
      "& span": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    rowFont: {
      " & div:nth-child(2)": {
        fontFamily: "Poppins, sans-serif",
        fontSize: "12px",
        fontWeight: "500",
      },
    },
    bgBlur: {
        backdropFilter: "blur(3px)",
        backgroundColor:'rgba(0,0,30,0.4)'
    },
    loader: {
        top: `50%`,
        left: `43%`,
        position: 'absolute',
        backgroundColor: 'red',
        boxShadow: 'none',
    },

    loader1 :{
        display: "flex",
      "& > * + *": {
        marginLeft: theme.spacing(2)
      },
      position: "absolute",
      top: 0,
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    }
  }));

  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
  });
  const [rows, setRows] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [value, setValue] = React.useState(new Date());
  const [open, setOpen] = React.useState({});
  const wholeState = useSelector((state) => state.reportReducer);
  const projectRows = wholeState.projects;

  const [showPopup, setShowPopup] = useState(false);
  const [showCpPopup, setShowCpPopup] = useState(false);
  const [checked, setChecked] = React.useState(true);
  const [rowId, setRowId] = useState(null);
  let {customerId, userType, userId} = handleCustomerData();
  const loadCustomer = () => {
    let cusId, uid;
  if(userType == "CP"){
      cusId = 'null';
      uid = userId;
    }else if(userType == "RA" || userType == "SA" || userType == "AD"){
      cusId = 'null';
      uid = 'null';
    }else{
      cusId = customerId;
      uid = 'null';
    }
    setLoader(true)
     axios.get(`${API_URL}getAllUserMasterList/${uid}/${cusId}`).then((res) => {
      setRows(res.data.data);
      setLoader(false)
    });
  };
  useEffect(() => {
    loadCustomer();
  }, []);

  const navigate = useNavigate();
  const classes = useStyles();

  const editMaster = (id) => {
    setRowId(id);
    setShowPopup(true);

  };
  const changePassword = (id) =>{
    setRowId(id);
    setShowCpPopup(true);
  }
  const tableStyle = {
    rows: {
      fontSize: "25px",
      color: "red",
    },
    header: {
      fontWeight: 600,
    },
    MUIDataTableBodyCell: {
      head: {
        backgroundColor: "red !important",
      },
    },
    MUIDataTableHeadCell: {
      display: "flex",
      justifyContent: "center",
    },
  };

  const handleChange = async (event, index) => {
    setLoader(true)
    // const payload = {
    //     "user_status" : event  ? "A" : "D",
    //     "user_name": rows[index].user_name,
    //     "user_type": rows[index].user_type,
    //     "modified_by": rows[index].customer_name || "root"
    // }
    setChecked(checked);
    await axios.get(`${API_URL}toggleUserStatus/${rows[index].id}`).then(res => {
        loadCustomer();
    });
}
  const columns = [
    {
      name: "id",
      label: "User Id",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
      },
    },
    {
      name: "user_name",
      label: "User Name",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
      },
    },
    {
      name: "user_type",
      label: "User Type",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <>
              <Chip
                color="info"
                size="small"
                label={rows[dataIndex].user_type_desc}
              />
            </>
          );
        },
      },
    },
    {
      name: "customer_name",
      label: "Customer Name",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
        
          return (
               <p>
              {rows[dataIndex]?.customer_name || "-"}
              </p>
           );
        },
      },
    },
    {
      name: "user_status",
      label: "User Status",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          let userType = {
            status: "",
            color: "success",
          };
          if (rows[dataIndex].user_status === "D") {
            userType["status"] = "Deactivated";
            userType["color"] = "error";
          } else {
            userType["status"] = "Activated";
            userType["color"] = "success";
          }
          return (
            <>
              <Chip
                color={userType.color}
                size="small"
                label={userType.status}
              />
            </>
          );
        },
      },
    },
    // {
    //   name: "ACT/DE-ACT",
    //   label: "ACT/DEC",
    //   options: {
    //     setCellProps: (value) => {
    //       return {
    //         className: `${classes.rowFont} ${classes.arrangeCenter}`,
    //       };
    //     },
    //     setCellHeaderProps: () => ({
    //       className: `${classes.centeredHeader} ${classes.columnHeader}`,
    //     }),
    //     customBodyRenderLite: (dataIndex, rowIndex) => {
    //       return (
    //         (rows[dataIndex].id != userId && userType != "CP") ?
    //           <Switch
    //           checked={rows[dataIndex].user_status === 'A' ? true : false}
    //           onChange={(e) => handleChange(e.target.checked, rowIndex)}
    //           inputProps={{ "aria-label": "controlled" }}
    //         />
    //         :
    //         " - "
              
    //       );
    //     },
    //   },
    // },

    {
      name: "Actions",
      options: {
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div style={{ display: "flex" }} className="actionBtns">
              
            {rows[dataIndex].id != userId && (userType != "CP" || userType != "MR" || userType != "SE" )?  
            <> 
            <Button
            aria-label="edit"
            onClick={() => editMaster(rows[dataIndex].id)}
            >
            <ModeEditOutlineOutlinedIcon sx={{ color: "#0a0a9bde" }} />
          </Button>
            <Button
                aria-label="delete"
                onClick={() => deleteMaster(rows[dataIndex].id)}
              >
                <DeleteOutlineOutlinedIcon sx={{ color: "#dc0000" }} />
              </Button>
              <Button
                aria-label="password-change"
                onClick={() => changePassword(rows[dataIndex].id)}
              >
                <PasswordIcon  />
              </Button>
              </>
            : <Button
                aria-label="delete"
              > - 
              </Button>}
             
            </div>
          );
        },
      },
    },
  ];

  const deleteMaster = (id) => {
    setOpen((prevState) => {
      return {
        open: true,
        content: "Are you sure you want to delete?",
        onSubmit: () => {
          axios.delete(`${API_URL}userMaster/${id}`).then((res) => {
            setOpen((prevState) => {
              return {
                open: false,
              };
            });
            setOpenSnackbar((prevState) => {
              return {
                ...prevState,
                open: true,
                message: " User details deleted Successfully",
              };
            });
            loadCustomer();
          });
        },
        actionName: "Delete",
      };
    });
  };


  const options = {
    selectableRows: false,
    textLabels: {
        body: {
            noMatch: loader && <div>Loading...</div>
        },
    }
  };

  const handleClose = (title = "", content = "") => {
    setOpen((prevState) => {
      return {
        open: false,
      };
    });
    setRowId(null)
  };

  

  return (
    <>
     <MessageModal handleClose={handleClose} open={open} />
    {loader && <Loader /> }
      <div className={`${classes.root}`}>
        <MUIDataTable
          data={rows}
          columns={columns}
          options={options}
          elevation={4}
          customTheme={tableStyle}
          title={
            <Button variant="contained" onClick={() => {setShowPopup(true);setRowId(null);}}>
              Create
            </Button>
          }
        //   components={{ TableBody: BodyComponent }}
        
        />
      </div>
      <PopupComponent
        open={showPopup}
        onClose={setShowPopup}
        title={"User Details"}
        width={"30%"}
      >
        <EditForm id={rowId} rowId = {setRowId} onClose={setShowPopup} loadCustomer={()=> loadCustomer()}/>
      </PopupComponent>
      <PopupComponent
        open={showCpPopup}
        onClose={setShowCpPopup}
        title={"Change Password"}
        width={"60%"}
      >
        <UserChangePwd id={rowId}  onClose={setShowCpPopup} loadCustomer={()=> loadCustomer()}/>
      </PopupComponent>
      
    </>
  );
}
