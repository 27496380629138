import React, { useState, useEffect } from "react";
// project import
// import dashboard from "./dashboard";
import reports from "./reports";
import master from "master/index";
import DataGeneration from "../data-genaration";
// ==============================|| MENU ITEMS ||============================== //

export default function MenuList() {
  const [userType, setUserType] = useState(null);
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const userType = userData?.user_type;
    setUserType(userType);
  }, []);
  let menuItems = {};
  switch (userType) {
    case "SE":
      menuItems = {
        items: [DataGeneration, reports],
      };
      break;
    case "CM":
      menuItems = {
        items: [master, reports],
      };
      break;
    case "CP":
      menuItems = {
        items: [reports],
      };
      break;
    case "SA":
      menuItems = {
        items: [DataGeneration, master, reports],
      };
      break;
    case "RA":
      menuItems = {
        items: [DataGeneration, master, reports],
      };
      break;
    case "MR":
      menuItems = {
        items: [reports],
      };
      break;
    case "AD":
      menuItems = {
        items: [reports],
      };
      break;
    default:
      menuItems = {
        items: [],
      };
  }

  return menuItems;
}
// export default menuItems;
