import React,{useState, useEffect} from 'react';
// material-ui
import { Box, Typography } from "@mui/material";

// project import
import NavGroup from "./NavGroup";
// import menuItem from "../../../../../menu-items";
import MenuList from "../../../../../menu-items"

const gradientBg = {
  background:"linear-gradient(to right, rgb(0, 39, 101), rgb(9, 107, 185))" 
};


const Navigation = () => {
//  const [menuList, setMenuList] = useState({})
//  useEffect(() => {
//    const menuItems = MenuList()
//    setMenuList(menuItems)
//  },[])
  const menuItems = MenuList()
  const navGroups = menuItems?.items.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return (
    <Box sx={{ pt: 2 }} style={gradientBg}>
      {navGroups}
    </Box>
  );
};

export default Navigation;
