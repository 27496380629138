import { LOGIN_DATA, PROJECT_LIST } from "store/reducers/actions";

export function projects_list(data) {
  return {
    type: PROJECT_LIST,
    payload: data,
  };
}

export function login_dispatch(data, token) {
  return {
    type: LOGIN_DATA,
    payload: { ...data, token },
  };
}
export function clear_data() {
  return {
    type: clear_data,
  };
}
