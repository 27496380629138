// material-ui
import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import axios from "axios";
import Loader from "components/Loader";

import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@mui/material/Paper";
import MUIDataTable from "mui-datatables";
import MessageModal from "common/MessageModal";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import "../masterStyles.css";
import Snackbar from "common/Snackbar";
import Chip from "@mui/material/Chip";
import PopupComponent from "common/popup";
import TurbineCreateEditModal from "./TurbineCreateEditModal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    " & .MuiPaper-root": {
      boxShadow: "0px 3px 15px rgb(0 0 0 / 10%)",
      borderRadius: "8px",
    },
  },
  columnHeader: {
    "& span": {
      "& Button": {
        fontWeight: "600",
        fontFamily: "Poppins, sans-serif",
      },
    },
  },
  arrangeCenter: {
    textAlign: "center",
  },
  centeredHeader: {
    "& span": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  rowFont: {
    " & div:nth-child(2)": {
      fontFamily: "Poppins, sans-serif",
      fontSize: "12px",
      fontWeight: "500",
    },
  },
}));

const TurbineList = () => {
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
  });
  const API_URL = process.env.REACT_APP_BACKEND_API;
  const loadCustomer = () => {
    setLoader(true);
    axios.get(`${API_URL}turbine`).then((res) => {
      setRows(res.data.data);
      setLoader(false);
    });
  };
  React.useEffect(() => {
    loadCustomer();
  }, []);

  const navigate = useNavigate();
  const classes = useStyles();

  const editTurbine = (id) => {
    navigate("/turbine-edit", { state: { id: `${id}` } });
  };
  const tableStyle = {
    root: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      " & .MuiPaper-root": {
        boxShadow: "0px 3px 15px rgb(0 0 0 / 10%)",
        borderRadius: "8px",
        width: "100% !important",
      },
    },
    rows: {
      fontSize: "25px",
      color: "red",
    },
    header: {
      fontWeight: 600,
    },
    MUIDataTableBodyCell: {
      head: {
        backgroundColor: "red !important",
      },
    },
    MUIDataTableHeadCell: {
      display: "flex",
      justifyContent: "center",
    },
  };
  const columns = [
    {
      name: "turbine_id",
      label: "Turbine Id",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: classes.columnHeader,
        }),
      },
    },
    // {
    //   name: "turbine_type_name",
    //   label: "Turbine Type",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     setCellProps: (value) => {
    //       return {
    //         className: `${classes.rowFont} ${classes.arrangeCenter}`,
    //       };
    //     },
    //     setCellHeaderProps: () => ({
    //       className: classes.columnHeader,
    //     }),
    //   },
    // },
    {
      name: "turbine_reference",
      label: "Turbine Reference",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: classes.columnHeader,
        }),
      },
    },
    {
      name: "turbine_output",
      label: "Turbine output",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: classes.columnHeader,
        }),
      },
    },
    {
      name: 'turbine_unit_master',
      label: "Unit",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: classes.columnHeader,
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (rows[dataIndex].turbine_unit_master?.turbine_unit
          );
        },
      },
    },
    {
      name: "assigned_status",
      label: "Assigned Status",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: classes.columnHeader,
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <>
              <Chip
                variant="outlined"
                color="info"
                size="small"
                label={rows[dataIndex].assigned_status}
              />
            </>
          );
        },
      },
    },
    {
      name: "Actions",
      options: {
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="actionBtns"
            >
              <Button
                aria-label="edit"
                onClick={() => editTurbine(rows[dataIndex].turbine_id)}
              >
                <ModeEditOutlineOutlinedIcon sx={{ color: "#0a0a9bde" }} />
              </Button>

              <Button
                aria-label="edit"
                onClick={() => deleteTurbine(rows[dataIndex].turbine_id)}
              >
                <DeleteOutlineOutlinedIcon sx={{ color: "#dc0000" }} />
              </Button>
            </div>
          );
        },
        // setCellHeaderProps: () => ({ style: {alignItems}})
      },
    },
  ];

  const deleteTurbine = (id) => {
    setOpen((prevState) => {
      return {
        open: true,
        content: "Are you sure you want to delete?",
        onSubmit: () => {
          axios.delete(`${API_URL}turbine/${id}`).then((res) => {
            setOpen((prevState) => {
              return {
                open: false,
              };
            });
            setOpenSnackbar((prevState) => {
              return {
                ...prevState,
                open: true,
                message: " Turbine details deleted Successfully",
              };
            });
            loadCustomer();
          });
        },
        actionName: "Delete",
      };
    });
  };
  const options = {
    selectableRows: false,
  };

  const [rows, setRows] = React.useState([]);
  const [showTable, setShowTable] = React.useState(false);

  // const [projectRows, setProjectRows] = React.useState([]);

  const [project, setProject] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [viewType, setViewType] = React.useState("S");
  const [value, setValue] = React.useState(new Date());
  const [projectHelperText, setProjectHelperText] = React.useState("");
  const [open, setOpen] = React.useState({});
  const [rowId, setRowId] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const wholeState = useSelector((state) => state.reportReducer);
  const projectRows = wholeState.projects;

  const handleClose = (title = "", content = "") => {
    setOpen((prevState) => {
      return {
        open: false,
      };
    });
  };

  return (
    <div className="table-container">
      {loader && <Loader />}

      <Divider />
      {rows?.length > 0 ? (
        <div>
          <div className={`${classes.root}`}>
            <MessageModal handleClose={handleClose} open={open} />
            <MUIDataTable
              data={rows}
              columns={columns}
              options={options}
              elevation={4}
              customTheme={tableStyle}
              title={
                <Button
                  variant="contained"
                  // onClick={() => navigate("/turbine-create")}
                  onClick={() => {
                    setShowPopup(true);
                    setRowId(null);
                  }}
                >
                  Create
                </Button>
              }
            />
          </div>
        </div>
      ) : (
        <TableContainer component={Paper} className="mt-3">
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">No Data Found</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      )}
      <Snackbar open={openSnackbar} setOpen={setOpenSnackbar} />
      <PopupComponent
        open={showPopup}
        onClose={setShowPopup}
        title={rowId ? "Update Turbine" : "Create Turbine"}
        width={"50%"}
      >
        <TurbineCreateEditModal
          id={rowId}
          onClose={setShowPopup}
          refreshData={() => loadCustomer()}
        />
      </PopupComponent>
    </div>
  );
};

export default TurbineList;
