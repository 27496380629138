import React, { useState } from "react";
import { Button } from "@mui/material";
import "../formmaster.css";
import TextField from "@mui/material/TextField";
import axios from "../../../node_modules/axios/index";
import MessageModal from "common/MessageModal";
import { useNavigate } from "../../../node_modules/react-router-dom/index";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "common/Snackbar";
import { MenuItem } from "../../../node_modules/@material-ui/core/index";
import Select from "@mui/material/Select";

const useStyles = makeStyles((theme) => ({
  TextField: {
    width: "100%",
    margin: "auto",
  },
  textField_label: {
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
  },
}));

const ProjectMasterCreate = () => {
  const classes = useStyles();
  const autoCompleteRef = React.useRef(null);

  const navigate = useNavigate();
  const [open, setOpen] = useState({});
  const [error, setError] = useState(false);
  const [userData, setUserData] = useState({});
  const [formValueError, setFormValueError] = useState({});
  const [formValue, setFormValue] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
  });

  const [customerList, setCustomerList] = useState([]);
  const [siteList, setSiteList] = useState([]);

  const handleClose = () => {
    setOpen((prevState) => {
      return {
        open: false,
      };
    });
    setFormValue({});
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (!value) {
      setFormValueError((prevState) => {
        return {
          ...prevState,
          [name]: true,
        };
      });
    } else {
      setFormValueError((prevState) => {
        return {
          ...prevState,
          [name]: false,
        };
      });
      setFormValue((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
  };
  const API_URL = process.env.REACT_APP_BACKEND_API;
  const create = () => {
    let msg = "";
    axios.post(`${API_URL}projectMaster`, formValue).then((res) => {
      setError(res.data.error);
      if (res.data.error) {
        if (res?.data?.data?.denmark_project_id) {
          msg = res?.data?.data?.denmark_project_id[0];
        } else if (res?.data?.data?.project_ref_id) {
          msg = res?.data?.data?.project_ref_id[0];
        } else if (res?.data?.data?.project_name) {
          msg = res?.data?.data?.project_name[0];
        } else if (res?.data?.data?.customer_id) {
          msg = res?.data?.data?.customer_id[0];
        } else if (res?.data?.data?.site_id) {
          msg = res?.data?.data?.site_id[0];
        }
      } else {
        msg = res?.data.message;
        setFormValue({
          customer_status: "A",
          created_by: userData?.customer_name,
        });
      }

      setOpenSnackbar((prevState) => {
        return {
          ...prevState,
          open: true,
          message: msg,
        };
      });
    });
  };
  const goBack = () => {
    navigate("/project-master");
  };
  React.useEffect(() => {
    axios.get(`${API_URL}getActiveCustomer`).then((res) => {
      setCustomerList(res.data.data);
    });
    axios.get(`${API_URL}siteMaster`).then((res) => {
      setSiteList(res.data.data);
    });
    const userData = JSON.parse(localStorage.getItem("userData"));
    setUserData(userData);
    setFormValue({
      customer_status: "A",
      created_by: userData?.customer_name,
    });
  }, []);

  return (
    <>
      <MessageModal handleClose={handleClose} open={open} />
      <div className="">
        <div className="masterCrudForm pt-2">
          <TextField
            className={classes.TextField}
            label={
              formValueError["project_ref_id"] ? "Required" : "Project Ref ID"
            }
            variant="outlined"
            size="medium"
            required
            id="project_ref_id"
            type="text"
            name="project_ref_id"
            value={formValue.project_ref_id || ""}
            onChange={handleChange}
            error={formValueError["project_ref_id"]}
            InputLabelProps={{
              shrink: formValue["project_ref_id"] ? true : false,
              className: formValue["project_ref_id"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={
              formValueError["denmark_project_id"]
                ? "Required"
                : "Denmark Project ID"
            }
            variant="outlined"
            size="medium"
            required
            id="denmark_project_id"
            type="text"
            error={formValueError["denmark_project_id"]}
            value={formValue.denmark_project_id || ""}
            name="denmark_project_id"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["denmark_project_id"] ? true : false,
              className: formValue["denmark_project_id"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />

          <TextField
            className={classes.TextField}
            label={formValueError["project_name"] ? "Required" : "Project Name"}
            variant="outlined"
            size="medium"
            id="project_name"
            type="text"
            required
            error={formValueError["project_name"]}
            value={formValue.project_name || ""}
            name="project_name"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["project_name"] ? true : false,
              className: formValue["project_name"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          
          <TextField
            className={classes.TextField}
            label={formValueError["customer_id"] ? "Required" : "Customer"}
            error={formValueError["customer_id"]}
            variant="outlined"
            size="medium"
            id="customer_id"
            select
            required
            value={formValue?.customer_id || ""}
            name="customer_id"
            onChange={handleChange}
            // InputLabelProps={{
            //   shrink: formValue?.["customer_id"] ? true : false,
            //   className: formValue?.["customer_id"]
            //     ? `${classes.textField_label}`
            //     : undefined,
            // }}
          >
            {customerList?.map((option) => (
              <MenuItem key={option.customer_id} value={option.customer_id}>
                {option.customer_name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            className={classes.TextField}
            label={formValueError["site_id"] ? "Required" : "Site"}
            error={formValueError["site_id"]}
            variant="outlined"
            size="medium"
            id="site_id"
            required
            select
            value={formValue?.site_id || ""}
            name="site_id"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue?.["site_id"] ? true : false,
              className: formValue?.["site_id"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          >
            {siteList?.map((option) => (
              <MenuItem key={option.site_id} value={option.site_id}>
                {option.site_name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            className={classes.TextField}
            label={
              formValueError["total_turbines"] ? "Required" : "Total Turbines"
            }
            error={formValueError["total_turbines"]}
            variant="outlined"
            size="medium"
            id="total_turbines"
            type="text"
            required
            value={formValue.total_turbines || ""}
            name="total_turbines"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["total_turbines"] ? true : false,
              className: formValue["total_turbines"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={
              formValueError["zone_support_email"]
                ? "Required"
                : "Zone support Email"
            }
            error={formValueError["zone_support_email"]}
            variant="outlined"
            size="medium"
            id="zone_support_email"
            type="text"
            value={formValue.zone_support_email || ""}
            name="zone_support_email"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["zone_support_email"] ? true : false,
              className: formValue["zone_support_email"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={
              formValueError["zone_manager_email"]
                ? "Required"
                : "Zone Manager Email"
            }
            error={formValueError["zone_manager_email"]}
            variant="outlined"
            size="medium"
            id="zone_manager_email"
            type="text"
            value={formValue.zone_manager_email || ""}
            name="zone_manager_email"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["zone_manager_email"] ? true : false,
              className: formValue["zone_manager_email"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label={formValueError["status"] ? "Required" : "Status"}
            error={formValueError["status"]}
            variant="outlined"
            size="medium"
            id="status"
            required
            value={formValue?.status || ""}
            name="status"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue?.["status"] ? true : false,
              className: formValue?.["status"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          >
            <MenuItem key="A" value="A">
              Activate
            </MenuItem>
            <MenuItem key="D" value="D">
              De-Activate
            </MenuItem>
          </TextField>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formValue?.status || ""}
            name="status"
            required
            label={formValueError["status"] ? "Required" : "Status"}
            error={formValueError["status"]}
            onChange={handleChange}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </div>
        <div className="actionBtns crudBtnpadding">
          <Button
            variant="contained"
            onClick={create}
            style={{ margin: "auto 10px", width: "200px" }}
          >
            Submit
          </Button>
        </div>
      </div>
      <Snackbar open={openSnackbar} error={error} setOpen={setOpenSnackbar} />
    </>
  );
};
export default ProjectMasterCreate;
