import React, { useState } from "react";
import {
  Button,
} from "@mui/material";
import "../formmaster.css";
import TextField from '@mui/material/TextField';
import axios from "../../../node_modules/axios/index";
import MessageModal from "common/MessageModal";
import { useNavigate } from "../../../node_modules/react-router-dom/index";
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from "../../../node_modules/react-router/index";
import Snackbar from "common/Snackbar";

const useStyles = makeStyles(
  theme => ({
    TextField:{
      width: "330px",
      // border: "1px solid #e2e2e25e",
      // borderRadius: theme.shape.borderRadius

    },
    textField_label: {
      fontSize: "16px",
      fontFamily: 'Poppins, sans-serif',
    },
    hideLabel : {
        display:"none"
    }
  }))

const LocatoinEdit = () => {
  const classes = useStyles();
  const location = useLocation();

  const id = location.state.id;
  const navigate = useNavigate();
  const [open, setOpen] = useState({});
  const [formValue, setFormValue] = useState({
    customer_status: "A",
    created_by: "Araman",
  });
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: ""
  })

  React.useEffect(()=>{
    axios.get(`${API_URL}location/${id}`).then(res => {
        setFormValue(res.data.data)
        setFormValue((prevState) => {
          return {
              ...prevState,
            
             ...{customer_status:"A",modified_by:"Araman"}
          }
      })
    })
},[])

  const handleClose = () =>{
    setOpen((prevState)=>{
        return {
            open:false,
        }
    })
    setFormValue({modified_by:"Araman",})
    
    navigate('/location-list');
}
  // const [formValue, setFormValue] = useState({customer_status:"A",created_by:"Araman"})
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue((prevState) => {
        return {
            ...prevState,
            [name]: value,
           ...{customer_status:"A",modified_by:"Araman"}
        }
    })
}

  const API_URL = process.env.REACT_APP_BACKEND_API;
  const update = async() => {
    await axios.put(`${API_URL}location/${id}`, formValue).then(res => {
        // console.log("Res==>", res.data)
        // setOpen((prevState)=>{
        //     return {
        //         open:true,
        //         content:res.data.message,
        //         title:res.data.code,
        //     }
        // })
        setOpenSnackbar((prevState) => {
            return {
                ...prevState,
                open: true,
                message:" Location details updated Successfully"
            }
        })
    })
}

  const goBack = () => {
    navigate("/location-list")
}
  return (
    <>
      <MessageModal handleClose={handleClose} open={open} />
      <div className="createCustomerWrapper">
          <div className="masterCrudForm">
          <TextField  
                className={classes.TextField} 
                label="Location Id"
                variant="outlined" 
                size="medium"
                id="location_id"
                type="text"
                readOnly = "true"
                value={formValue.location_id || ""}
                name="location_id"
                onChange={handleChange}
                inputProps={
					{ readOnly: true, }
				}
                InputLabelProps={{
                  shrink: formValue["location_id"] ? true : false,
                  className : formValue["location_id"] ? `${classes.textField_label}` : undefined,
                }}
               />
                {/* <TextField  
                className={classes.TextField} 
                label="Location Id"
                variant="outlined" 
                size="medium"
                id="location_id"
                type="text"
                value={formValue.location_id || ""}
                name="location_id"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: formValue["location_id"] ? true : false,
                  className : formValue["location_id"] ? `${classes.textField_label}` : undefined,
                }}
               /> */}
                <TextField  
                className={classes.TextField} 
                label="Location Name"
                variant="outlined" 
                size="medium"
                id="location_name"
                type="text"
                value={formValue.location_name || ""}
                name="location_name"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: formValue["location_name"] ? true : false,
                  className : formValue["location_name"] ? `${classes.textField_label}` : undefined,
                }}
               />
                <TextField  
                className={classes.TextField} 
                label="Country Id"
                variant="outlined" 
                size="medium"
                id="country_id"
                type="text"
                value={formValue.country_id || ""}
                name="country_id"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: formValue["country_id"] ? true : false,
                  className : formValue["country_id"] ? `${classes.textField_label}` : undefined,
                }}
               />
          </div>
          <div className="actionBtns crudBtnpadding">
          <Button
            variant="contained"
            onClick={update}
            style={{ margin: "auto 10px",  width: "200px"  }}
            >
            Update
        </Button>
        <Button
            variant="contained"
            onClick={goBack}
            style={{ margin: "auto 10px", width: "200px" }}
            >
            Back
        </Button>
          </div>
         </div>
         <Snackbar open={openSnackbar} setOpen={setOpenSnackbar} />

    </>
  );
};

export default LocatoinEdit
