// material-ui
import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Divider from "@mui/material/Divider";
import axios from "../../../node_modules/axios/index";
import Loader from "components/Loader";
import TextField from "@mui/material/TextField";
import "./DailyReport.css";
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";

const YearlyReport = () => {
  const API_URL = process.env.REACT_APP_BACKEND_API;
  const autoCompleteRef = React.useRef(null);
  const [rows, setRows] = React.useState([]);
  const [project, setProject] = React.useState("");
  const [loader, setLoader] = React.useState(false);

  const [viewType, setViewType] = React.useState("S");
  var fromDate = new Date();
  fromDate.setFullYear(fromDate.getFullYear() - 3);

  const [fromYear, setFromYear] = React.useState(fromDate);
  const [toYear, setToYear] = React.useState(new Date());
  const [projectHelperText, setProjectHelperText] = React.useState("");

  const wholeState = useSelector((state) => state.reportReducer);
  const projectRows = wholeState.projects;

  const handleViewChange = (event, newViewType) => {
    setViewType(newViewType);
  };

  const handleChange = (event) => {
    setProject(event.target.value);
  };
  const reset = (event) => {
    setRows([]);
  };
  const fetchQuery = () => {
    if (project == "") {
      setProjectHelperText("Project is required");
    } else {
      setLoader(true);
      const fromParse = new Date(fromYear);
      const toParse = new Date(toYear);

      const fromYearParsed = fromParse.getFullYear();
      const toYearParsed = toParse.getFullYear();

      axios
        .get(
          `${API_URL}getYearlyReport/${project}/${fromYearParsed}/${toYearParsed}`
        )
        .then((res) => {
          console.log("res-----", res);
          setRows(res.data.data);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const clearReport = () => {
    setRows([]);
    if (project !== "") {
      autoCompleteRef.current.querySelector("button").click();
      setProject("");
    }
    setViewType("S");
  };
  const printReport = () => {
    var content = document.getElementById("printContent");
    var mywindow = window.open("", "PRINT", "height=400,width=600");

    mywindow.document.write(
      "<html><head><title>" + document.title + "</title>"
    );
    mywindow.document.write("</head><body >");
    mywindow.document.write("<h1>" + document.title + "</h1>");
    mywindow.document.write(document.getElementById("printContent").innerHTML);
    mywindow.document.write("</body></html>");

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
    // var content = document.getElementById("printContent");
    // var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    // pri.document.open();
    // pri.document.write(content.innerHTML);
    // pri.document.close();
    // pri.focus();
    // pri.print();
  };

  const tableRef = useRef(null);
  const fromParse = new Date(fromYear);
  const toParse = new Date(toYear);

  const fromYearParsed = fromParse.getFullYear();
  const toYearParsed = toParse.getFullYear();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Yearly_report_${fromYearParsed}_to_${toYearParsed}`,
    sheet: `${fromYearParsed}_to_${toYearParsed}`,
  });

  return (
    <div className="container">
      {loader && <Loader />}
      <Card elevation={3}>
        <CardContent>
          <div className="row">
            <div className="col-4">
              <FormControl fullWidth>
                {/* <Autocomplete
                  onChange={(event, value) => setProject(value?.project_ref_id)}
                  id="Project"
                  getOptionLabel={(option) =>
                    option.project_name + "(" + option.project_ref_id + ")"
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.project_id}>
                        {option.project_name +
                          " (" +
                          option.project_ref_id +
                          ")"}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Project"
                      size="small"
                      error={projectHelperText != "" ? true : false}
                      helperText={projectHelperText}
                    />
                  )}
                  ref={autoCompleteRef}
                /> */}
                <Autocomplete
                  onChange={(event, value) => setProject(value?.project_id)}
                  id="Project"
                  options={projectRows || {}}
                  getOptionLabel={(option) =>
                    option.project_name + "(" + option.project_ref_id + ")"
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.project_id}>
                        {option.project_name +
                          " (" +
                          option.project_ref_id +
                          ")"}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Project"
                      size="small"
                      error={projectHelperText != "" ? true : false}
                      helperText={projectHelperText}
                    />
                  )}
                  ref={autoCompleteRef}
                />
              </FormControl>
            </div>
            <div className="col-2">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={["year"]}
                  label="From Year"
                  value={fromYear}
                  onChange={(newValue) => {
                    setFromYear(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} fullWidth />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-2">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={["year"]}
                  label="To Year"
                  value={toYear}
                  onChange={(newValue) => {
                    setToYear(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} fullWidth />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col d-flex justify-content-center">
              <Button
                variant="contained"
                onClick={fetchQuery}
                style={{ margin: "auto 10px" }}
              >
                Query
              </Button>
              <Button
                variant="contained"
                style={{ margin: "auto 10px" }}
                onClick={clearReport}
              >
                Clear
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
      <Divider />
      {rows.length > 0 ? (
        <div className="card  bg-light m-auto mt-3 w-75">
          <div className="card-body">
            <div className="row">
              <div className="col" id="printContent" ref={tableRef}>
                {/* <TableContainer component={Paper}> */}
                <Table
                  className="tableInfoHeader"
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" colSpan={4}>
                        <h6 style={{ marginTop: "0.5rem", fontWeight: "600" }}>
                          VESTAS WIND TECHNOLOGY INDIA PRIVATE LIMITED
                        </h6>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" colSpan={4}>
                        <h6>GENERATION REPORT - Yearly</h6>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        Customer Name : <b>{rows[0]?.customer_name}</b>
                      </TableCell>
                      <TableCell>
                        Project Name: <b>{rows[0]?.project_name}</b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table
                  className="mt-3"
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="Yearly Report table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Year</TableCell>
                      <TableCell align="center">LCS</TableCell>
                      <TableCell align="center">EB Export</TableCell>
                      <TableCell align="center">KVARH Import%</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.length > 0 &&
                      rows.map((row) => (
                        <TableRow>
                          <TableCell align="center">
                            {row?.report_date}
                          </TableCell>
                          <TableCell align="center">{row?.lcs}</TableCell>
                          <TableCell align="center">{row?.eb_export}</TableCell>
                          <TableCell align="center">
                            {row?.kvarhPercent}%
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
              <Table
                className="mt-3"
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="Yearly Report table"
              >
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      {/* <input
                        type="button"
                        className="btn btn-primary"
                        onClick={printReport}
                        id="Print"
                        value="Print"
                      /> */}
                      {rows?.length > 0 && (
                        <>
                          <Button
                            variant="contained"
                            style={{ margin: "auto 10px" }}
                            onClick={onDownload}
                          >
                            Export
                          </Button>
                          <input
                            type="button"
                            className="btn btn-primary"
                            id="Cancel"
                            onClick={reset}
                            value="Cancel"
                          />
                        </>
                      )}
                    </TableCell>
                    {/* <TableCell align="left">
                      <input
                        type="button"
                        className="btn btn-primary"
                        id="Cancel"
                        onClick={reset}
                        value="Cancel"
                      />
                    </TableCell> */}
                  </TableRow>
                </TableBody>
              </Table>
            </div>{" "}
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default YearlyReport;
