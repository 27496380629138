// types
import { createSlice } from "@reduxjs/toolkit";
import { clear_data } from "store/actions/reportaction";
import AccordionButton from "../../../node_modules/react-bootstrap/esm/AccordionButton";
import { LOGIN_DATA, PROJECT_LIST, START_LOADER, STOP_LOADER } from "./actions";

// initial state
const initialState = {
  projects: [],
  userData: [],
  loader: false
};

// ==============================|| REPORT - REDUCER ||============================== //
export default function reportReducer(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    // Do something here based on the different types of actions
    case PROJECT_LIST:
      return {
        projects: action.payload,
      };
    case LOGIN_DATA:
      return {
        userData: action.payload,
      };
    case clear_data:
      return {
        userData: [],
        projects: [],
      };
    case START_LOADER:
      return {
        loader: true,
      };
    case STOP_LOADER:
      return {
        loader: false,
      };
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
