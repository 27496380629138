import React, { useState } from "react";
import { Button } from "@mui/material";
import "../formmaster.css";
import TextField from "@mui/material/TextField";
import axios from "../../../node_modules/axios/index";
import MessageModal from "common/MessageModal";
import { useNavigate } from "../../../node_modules/react-router-dom/index";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/system";
import Snackbar from "common/Snackbar";
import Autocomplete from "@mui/material/Autocomplete";
const StyledTextField = styled(TextField, {
  name: "StyledTextField",
})({
  width: 300,
  "& .MuiInputBase-root": {
    "& .MuiInputLabel-shrink, & fieldset": {
      fontSize: "24px",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  TextField: {
    width: "330px",
  },
  textField_label: {
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
  },
  hideLabel: {
    display: "none",
  },
}));

const LocationCreate = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const [open, setOpen] = useState({});
  const [formValue, setFormValue] = useState({
    customer_status: "A",
    created_by: "Araman",
  });
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
  });
  const handleClose = () => {
    setOpen((prevState) => {
      return {
        open: false,
      };
    });
    setFormValue({ customer_status: "A", created_by: "Araman" });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    // console.log("formValue", name, value);
  };
  const API_URL = process.env.REACT_APP_BACKEND_API;
  const create = () => {
    // console.log({ formValue });
    axios.post(`${API_URL}location`, formValue).then((res) => {
      //   setOpen((prevState) => {
      //     return {
      //       open: true,
      //       content: res.data.message,
      //       title: res.data.code,
      //     };
      //   });
      setOpenSnackbar((prevState) => {
        return {
          ...prevState,
          open: true,
          message: " Location created Successfully",
        };
      });
    });
  };
  const goBack = () => {
    navigate("/location-list");
  };
  return (
    <>
      <MessageModal handleClose={handleClose} open={open} />
      <div className="createCustomerWrapper">
        <div className="masterCrudForm">
          <TextField
            className={classes.TextField}
            label="Location Id"
            variant="outlined"
            size="medium"
            id="location_id"
            type="text"
            value={formValue.location_id || ""}
            name="location_id"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["location_id"] ? true : false,
              className: formValue["location_id"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label="Location Name"
            variant="outlined"
            size="medium"
            id="Location Name"
            type="text"
            value={formValue.location_name || ""}
            name="location_name"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["location_name"] ? true : false,
              className: formValue["location_name"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label="Country Id"
            variant="outlined"
            size="medium"
            id="country_id"
            type="text"
            value={formValue.country_id || ""}
            name="country_id"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["country_id"] ? true : false,
              className: formValue["country_id"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
        </div>
        <div className="actionBtns">
          <Button
            variant="contained"
            onClick={create}
            style={{ margin: "auto 10px", width: "200px" }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={goBack}
            style={{ margin: "auto 10px", width: "200px" }}
          >
            Back
          </Button>
        </div>
      </div>
      <Snackbar open={openSnackbar} setOpen={setOpenSnackbar} />
    </>
  );
};
export default LocationCreate;
