// ==============================|| THEME CONFIG  ||============================== //

import axios from "../node_modules/axios/index";

const config = {
  defaultPath: "/dashboard/default",
  fontFamily: `'Public Sans', sans-serif`,
  i18n: "en",
  miniDrawer: false,
  container: true,
  mode: "light",
  presetColor: "default",
  themeDirection: "ltr",
};

export default config;
export const drawerWidth = 260;

export const twitterColor = "#1DA1F2";
export const facebookColor = "#3b5998";
export const linkedInColor = "#0e76a8";
const baseURL = process.env.REACT_APP_BACKEND_API;

export const axiosConfig = axios.create({
  // .. congigure axios baseURL
  baseURL: `${baseURL}`,
});
