// material-ui
import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import axios from "axios";
import Loader from "components/Loader";

import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@mui/material/Paper";
import MUIDataTable from "mui-datatables";
import MessageModal from "common/MessageModal";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import "../masterStyles.css";
import Snackbar from "common/Snackbar";
import Chip from "@mui/material/Chip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "85%",
    margin: "auto",
    " & .MuiPaper-root": {
      boxShadow: "0px 3px 15px rgb(0 0 0 / 10%)",
      borderRadius: "8px",
      flex: "1",
    },
  },
  columnHeader: {
    "& span": {
      "& Button": {
        fontWeight: "600",
        fontFamily: "Poppins, sans-serif",
      },
    },
  },
  arrangeCenter: {
    textAlign: "center",
  },
  centeredHeader: {
    "& span": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  rowFont: {
    " & div:nth-child(2)": {
      fontFamily: "Poppins, sans-serif",
      fontSize: "12px",
      fontWeight: "500",
    },
  },
}));

const TurbineUnitList = () => {
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
  });
  const API_URL = process.env.REACT_APP_BACKEND_API;
  const loadCustomer = () => {
    setLoader(true);
    axios.get(`${API_URL}turbineUnitMaster`).then((res) => {
      setRows(res.data.data);
      setLoader(false);
    });
  };
  React.useEffect(() => {
    loadCustomer();
  }, []);

  const navigate = useNavigate();
  const classes = useStyles();

  const editTurbine = (id) => {
    navigate("/turbine-unit-edit", { state: { id: `${id}` } });
  };
  const tableStyle = {
    rows: {
      fontSize: "25px",
      color: "red",
    },
    header: {
      fontWeight: 600,
    },
    MUIDataTableBodyCell: {
      head: {
        backgroundColor: "red !important",
      },
    },
    MUIDataTableHeadCell: {
      display: "flex",
      justifyContent: "center",
    },
  };
  const columns = [
    {
      name: "turbine_unit_id",
      label: "Turbine Unit Id",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
      },
    },
    {
      name: "turbine_unit",
      label: "Turbine Unit",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
      },
    },
    {
      name: "mult_factor_to_kwh",
      label: "Mult Factor to Kwh",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
      },
    },
    {
      name: "Actions",
      options: {
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div style={{ display: "flex" }} className="actionBtns">
              <Button
                aria-label="edit"
                onClick={() => editTurbine(rows[dataIndex].turbine_unit_id)}
              >
                <ModeEditOutlineOutlinedIcon sx={{ color: "#0a0a9bde" }} />
              </Button>

              <Button
                aria-label="edit"
                onClick={() => deleteTurbine(rows[dataIndex].turbine_unit_id)}
              >
                <DeleteOutlineOutlinedIcon sx={{ color: "#dc0000" }} />
              </Button>
            </div>
          );
        },
        // setCellHeaderProps: () => ({ style: {alignItems}})
      },
    },
  ];

  const deleteTurbine = (id) => {
    setOpen((prevState) => {
      return {
        open: true,
        content: "Are you sure you want to delete?",
        onSubmit: () => {
          axios.delete(`${API_URL}turbineUnitMaster/${id}`).then((res) => {
            setOpen((prevState) => {
              return {
                open: false,
              };
            });
            setOpenSnackbar((prevState) => {
              return {
                ...prevState,
                open: true,
                message: " Turbine details deleted Successfully",
              };
            });
            loadCustomer();
          });
        },
        actionName: "Delete",
      };
    });
  };
  const options = {
    selectableRows: false,
  };

  const [rows, setRows] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState({});

  const handleClose = (title = "", content = "") => {
    setOpen((prevState) => {
      return {
        open: false,
      };
    });
  };

  return (
    <div className="table-container">
      {loader && <Loader />}

      <Divider />
      {rows?.length > 0 ? (
        <div>
          <div className={`${classes.root}`}>
            <MessageModal handleClose={handleClose} open={open} />
            <MUIDataTable
              data={rows}
              columns={columns}
              options={options}
              elevation={4}
              customTheme={tableStyle}
              title={
                <Button
                  variant="contained"
                  onClick={() => navigate("/turbine-unit-create")}
                >
                  Create
                </Button>
              }
            />
          </div>
        </div>
      ) : (
        <TableContainer component={Paper} className="mt-3">
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">No Data Found</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      )}
      <Snackbar open={openSnackbar} setOpen={setOpenSnackbar} />
    </div>
  );
};

export default TurbineUnitList;
