// material-ui
import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import axios from "../../../node_modules/axios/index";
import Loader from "components/Loader";

import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@mui/material/Paper";
import MUIDataTable from "mui-datatables";
import MessageModal from "common/MessageModal";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import { useNavigate } from "../../../node_modules/react-router-dom/index";
import { makeStyles } from "@material-ui/core/styles";
import "../masterStyles.css";
import Snackbar from "common/Snackbar";
import Chip from "@mui/material/Chip";
import PopupComponent from "../../common/popup";
import EditForm from "./EditForm-new";
import CustomerCreateEditModal from "./CustomerCreateEditModal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    " & .MuiPaper-root": {
      boxShadow: "0px 3px 15px rgb(0 0 0 / 10%)",
      borderRadius: "8px",
      width: "100%",
    },
  },
  columnHeader: {
    "& span": {
      "& Button": {
        fontWeight: "600",
        fontFamily: "Poppins, sans-serif",
      },
      justifyContent: "center",
    },
  },
  arrangeCenter: {
    textAlign: "center",
  },
  centeredHeader: {
    "& span": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  rowFont: {
    " & div:nth-child(2)": {
      fontFamily: "Poppins, sans-serif",
      fontSize: "12px",
      fontWeight: "500",
    },
  },
}));

const handleCustomerData = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const customerId =
    typeof userData?.customer_id == "undefined" ? "" : userData?.customer_id;
  const userType = userData?.user_type;
  const userId = userData?.user_id;
  return { customerId, userType, userId };
};
const MasterList = () => {
  const {loader} = useSelector((state) => state.reportReducer);
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
  });
  const [showPopup, setShowPopup] = useState(false);
  const [rowId, setRowId] = useState("");
  const [type, setType] = useState("");

  const API_URL = process.env.REACT_APP_BACKEND_API;
  let { customerId, userType, userId } = handleCustomerData();

  const loadCustomer = () => {
    // setLoader(true);
    const customerURL =
      customerId != ""
        ? `${API_URL}getCustomers/${customerId}`
        : `${API_URL}getCustomers`;
    axios.get(customerURL).then((res) => {
      setRows(res.data.data);
      // setLoader(false);
    });
  };
  React.useEffect(() => {
    loadCustomer();
  }, []);

  const navigate = useNavigate();
  const classes = useStyles();

  const viewRow = (id) => {
    setShowPopup(true);
    setRowId(id);
    setType("view");
  };

  const editMaster = (id) => {
    setShowPopup(true);
    setRowId(id);
    setType("edit");
  };
  const tableStyle = {
    rows: {
      fontSize: "25px",
      color: "red",
      justifyContent: "center",
    },
    header: {
      fontWeight: 600,
    },
    MUIDataTableBodyCell: {
      head: {
        backgroundColor: "red !important",
      },
    },
    MUIDataTableHeadCell: {
      display: "flex",
      justifyContent: "center",
    },
  };
  const columns = [
    {
      name: "customer_id",
      label: "Customer Id",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: classes.columnHeader,
        }),
      },
    },
    {
      name: "customer_name",
      label: "Customer Name",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: classes.rowFont,
          };
        },
        setCellHeaderProps: () => ({
          className: classes.columnHeader,
        }),
      },
    },

    {
      name: "customer_phone",
      label: "Customer Phone",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: classes.rowFont,
          };
        },
        setCellHeaderProps: () => ({
          className: classes.columnHeader,
        }),
      },
    },
    {
      name: "customer_city",
      label: "Customer City",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: classes.rowFont,
          };
        },
        setCellHeaderProps: () => ({
          className: classes.columnHeader,
        }),
      },
    },
    {
      name: "site_city",
      label: "Site City",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: classes.rowFont,
          };
        },
        setCellHeaderProps: () => ({
          className: classes.columnHeader,
        }),
      },
    },
    {
      name: "customer_status",
      label: "Customer Status",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: classes.columnHeader,
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <>
              <Chip
                variant="outlined"
                color="info"
                size="small"
                label={
                  rows[dataIndex].customer_status == "A"
                    ? "Active"
                    : "De-Active"
                }
              />
            </>
          );
        },
      },
    },
    {
      name: "Actions",
      options: {
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="actionBtns"
            >
              <Button
                aria-label="view"
                onClick={() => viewRow(rows[dataIndex].customer_id)}
              >
                <VisibilitySharpIcon sx={{ color: "#5c5c6491" }} />
              </Button>
              <Button
                aria-label="edit"
                onClick={() => editMaster(rows[dataIndex].customer_id)}
              >
                <ModeEditOutlineOutlinedIcon sx={{ color: "#0a0a9bde" }} />
              </Button>

              <Button
                aria-label="edit"
                onClick={() => deleteMaster(rows[dataIndex].customer_id)}
              >
                <DeleteOutlineOutlinedIcon sx={{ color: "#dc0000" }} />
              </Button>
            </div>
          );
        },
        // setCellHeaderProps: () => ({ style: {alignItems}})
      },
    },
  ];
  const customerColumns = [
    {
      name: "customer_id",
      label: "Customer Id",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: classes.columnHeader,
        }),
      },
    },
    {
      name: "customer_name",
      label: "Customer Name",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: classes.rowFont,
          };
        },
        setCellHeaderProps: () => ({
          className: classes.columnHeader,
        }),
      },
    },

    {
      name: "customer_phone",
      label: "Customer Phone",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: classes.rowFont,
          };
        },
        setCellHeaderProps: () => ({
          className: classes.columnHeader,
        }),
      },
    },
    {
      name: "customer_city",
      label: "Customer City",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: classes.rowFont,
          };
        },
        setCellHeaderProps: () => ({
          className: classes.columnHeader,
        }),
      },
    },
    {
      name: "site_city",
      label: "Site City",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: classes.rowFont,
          };
        },
        setCellHeaderProps: () => ({
          className: classes.columnHeader,
        }),
      },
    },
    {
      name: "customer_status",
      label: "Customer Status",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: classes.columnHeader,
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <>
              <Chip
                variant="outlined"
                color="info"
                size="small"
                label={rows[dataIndex].customer_status}
              />
            </>
          );
        },
      },
    },
    {
      name: "Actions",
      options: {
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="actionBtns"
            >
              <Button
                aria-label="view"
                onClick={() => viewRow(rows[dataIndex].customer_id)}
              >
                <VisibilitySharpIcon sx={{ color: "#5c5c6491" }} />
              </Button>
            </div>
          );
        },
        // setCellHeaderProps: () => ({ style: {alignItems}})
      },
    },
  ];

  const deleteMaster = (id) => {
    setOpen((prevState) => {
      return {
        open: true,
        content: "Are you sure you want to delete?",
        onSubmit: () => {
          axios.delete(`${API_URL}customer/${id}`).then((res) => {
            setOpen((prevState) => {
              return {
                open: false,
              };
            });
            setOpenSnackbar((prevState) => {
              return {
                ...prevState,
                open: true,
                message: " Customer details deleted Successfully",
              };
            });
            loadCustomer();
          });
        },
        actionName: "Delete",
      };
    });
  };

  const options = {
    selectableRows: false,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <TableRow>
          <TableCell colSpan={rowData.length}>
            Custom expandable row option. Data: {JSON.stringify(rowData)}
          </TableCell>
        </TableRow>
      );
    },
  };

  const [rows, setRows] = React.useState([]);

  // const [projectRows, setProjectRows] = React.useState([]);

  // const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState({});

  const handleClose = (title = "", content = "") => {
    setOpen((prevState) => {
      return {
        open: false,
      };
    });
  };

  return (
    <div className="table-container">
      {loader && <Loader />}

      <Divider />
      {rows?.length > 0 ? (
        <div className={`${classes.root}`}>
          <MessageModal handleClose={handleClose} open={open} />
          <MUIDataTable
            data={rows}
            columns={customerId ? customerColumns : columns}
            options={options}
            elevation={4}
            customTheme={tableStyle}
            title={
              !customerId && (
                <Button
                  variant="contained"
                  // onClick={() => navigate("/customer-create")}
                  onClick={() => {
                    setShowPopup(true);
                    setRowId(null);
                    setType("create");
                  }}
                >
                  Create
                </Button>
              )
            }
          />
        </div>
      ) : (
        <TableContainer component={Paper} className="mt-3">
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">No Data Found</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      )}
      <Snackbar open={openSnackbar} setOpen={setOpenSnackbar} />
      {/* <PopupComponent
        open={showPopup}
        onClose={setShowPopup}
        title={"Customer Details"}
        width={"72%"}
      >
         <EditForm rowId={rowId} disabled={true} /> 
      </PopupComponent> */}
      <PopupComponent
        open={showPopup}
        onClose={setShowPopup}
        title={rowId ? "Customer Update" : "Create Customer"}
        width={"75%"}
      >
        <CustomerCreateEditModal
          id={rowId}
          onClose={setShowPopup}
          refreshData={() => loadCustomer()}
          type={type}
        />
      </PopupComponent>
    </div>
  );
};

export default MasterList;
