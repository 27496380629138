import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";

import { axiosConfig } from "config";
import axios from "../../../../node_modules/axios/index";
import "./LoginStyles.css";

import Snackbar from "common/Snackbar";
import { login_dispatch } from "store/actions/reportaction";
import { useDispatch } from "react-redux";

const useStyle = makeStyles((theme) => ({
  button: {
    width: "100%",
    background: "#3f51b5",
    color: "white",
    padding: "10px 10px",
    marginTop: "1rem",
    "&:hover": {
      backgroundColor: "#3f51b5",
    },
  },

  TextField: {
    width: "300px",
    marginBottom: "2rem !important",
  },
  fontStyling: {
    fontFamily: "Poppins, sans-serif !important",
    fontWeight: "400 !important",
    fontSize: "16px !important",
  },
  textField_label: {
    color: "black",
    fontWeight: "600 !important",
  },
  inputValue: {
    color: "#000000",
  },
  marginTop: {
    marginTop: "15px !important",
  },
  helperText: {
    fontFamily: "Poppins, sans-serif !important",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    color: "red !important",
  },
}));

const initialValues = {
  username: "",
  password: "",
};
const LoginForm = (props) => {
  let navigate = useNavigate();
  const classes = useStyle();
  const dispatch = useDispatch();

  const captchaRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState({});

  const [showError, setShowError] = useState({
    username: false,
    password: false,
  });

  const [formValues, setFormValues] = useState({
    ...initialValues,
  });

  const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
  const onCaptchaChange = (value) => {
    if (value) {
      setIsCaptchaChecked(true);
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (formValues.username == "") {
      setShowError({ username: true });
    } else if (formValues.password == "") {
      setShowError({ password: true });
    } else if (!isCaptchaChecked) {
      setOpen((prevState) => {
        return {
          ...prevState,
          open: true,
          error: true,
          message: "Captcha needs to checked",
        };
      });
    } else {
      setLoading(true);
      const token = captchaRef.current.getValue(); // Token should be sent with values to the backend
      captchaRef.current.reset();
      // Do post call here
      axiosConfig
        .post("/auth/login", formValues)
        .then((res) => {
          const data = res.data;
          setOpen((prevState) => {
            return {
              ...prevState,
              open: true,
              error: res.data?.error,
              message: res.data.message,
            };
          });
          if (data.status === true) {
            sessionStorage.setItem("token", data.token);
            localStorage.setItem("userData", JSON.stringify(data.data));
            // axios.defaults.headers.common["token"] = data.token;
            dispatch(login_dispatch(data.data, data.token));
            setLoading(false);
            navigate("/dashboard/default");
          }
        })
        .catch((err) => {
          setLoading(false);
          setOpen((prevState) => {
            return {
              ...prevState,
              open: true,
              error: err.response.data?.error,
              message: err.response.data?.message || "Network error",
              state: "error",
            };
          });
        });
    }
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
    setShowError({ username: false, password: false });
  };

  return (
    <div className="LoginWrapper">
      <form className="loginForm">
        <h1 className="heading">Login</h1>
        {/* <div className="inputWrapper">
          <label for="userId">Username</label> <br />
          <input
            type="text"
            id="userId"
            name="username"
            required
            value={formValues.username}
            onChange={(e) => handldChange(e)}
          />
        </div>
        <div className="inputWrapper">
          <label for="pass"> Password </label>
          <input
            type="password"
            id="pass"
            name="password"
            required
            value={formValues.password}
            onChange={(e) => handldChange(e)}
          />
        </div> */}
        <div style={{ marginTop: "2rem" }} className="inputSection">
          <TextField
            value={formValues.username}
            error={showError.username}
            className={classes.TextField}
            id="username"
            name="username"
            type="text"
            label="Username"
            variant="standard"
            InputLabelProps={{
              shrink: true,
              className: `${classes.textField_label} ${classes.fontStyling}`,
            }}
            InputProps={{
              className: `${classes.inputValue}`,
            }}
            required={true}
            onChange={(e) => handleChange(e)}
            helperText={showError.username ? "Enter a valid username" : ""}
            FormHelperTextProps={{
              classes: {
                root: classes.helperText,
              },
            }}
          />
          <TextField
            value={formValues.password}
            error={showError.password}
            className={`${classes.TextField} ${classes.marginTop}`}
            id="password"
            name="password"
            type="password"
            label="Password"
            variant="standard"
            InputLabelProps={{
              shrink: true,
              className: `${classes.textField_label} ${classes.fontStyling}`,
            }}
            InputProps={{
              className: `${classes.inputValue}`,
            }}
            required={true}
            onChange={(e) => handleChange(e)}
            helperText={showError.password ? "Enter password" : ""}
            FormHelperTextProps={{
              classes: {
                root: classes.helperText,
              },
            }}
          />
        </div>
        <div className="inputWrapper">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} // should be replaced with env variable
            onChange={onCaptchaChange}
            ref={captchaRef}
            size={"normal"}
          />
        </div>
        <LoadingButton
          loading={loading}
          loadingIndicator="Loading…"
          size="large"
          type="Submit"
          className={classes.button}
          onClick={onSubmit}
        >
          SUBMIT
        </LoadingButton>
      </form>
      <Snackbar
        open={open}
        error={open?.error}
        setOpen={setOpen}
        showCenter={true}
      />
    </div>
  );
};

export default LoginForm;
