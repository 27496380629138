import React, { useState } from "react";
import { Button } from "@mui/material";
import "../formmaster.css";
import TextField from "@mui/material/TextField";
import axios from "../../../node_modules/axios/index";
import MessageModal from "common/MessageModal";
import { useNavigate } from "../../../node_modules/react-router-dom/index";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "../../../node_modules/react-router/index";
import Snackbar from "common/Snackbar";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles((theme) => ({
  TextField: {
    width: "330px",
  },
  textField_label: {
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
  },
  hideLabel: {
    display: "none",
  },
}));

const ProjectMasterEdit = () => {
  const classes = useStyles();
  const location = useLocation();

  const id = location.state.id;
  const navigate = useNavigate();
  const [open, setOpen] = useState({});
  const [formValue, setFormValue] = useState({
    customer_status: "A",
    created_by: "Araman",
  });
  const [customers, setCustomers] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
  });

  React.useEffect(() => {
    axios.get(`${API_URL}projectMaster/${id}`).then((res) => {
      setFormValue(res.data.data);
    });
  }, []);
  React.useEffect(() => {
    axios.get(`${API_URL}getCustomers`).then((res) => {
      setCustomers(res.data.data);
    });
  }, []);

  const handleClose = () => {
    setOpen((prevState) => {
      return {
        open: false,
      };
    });
    setFormValue({ customer_status: "A", modified_by: "Araman" });
    navigate("/turbine-list");
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
        ...{ customer_status: "A", modified_by: "Araman" },
      };
    });
  };

  const API_URL = process.env.REACT_APP_BACKEND_API;
  const update = async () => {
    await axios
      .put(`${API_URL}projectMaster/${id}`, {
        ...formValue,
        customer_status: "A",
        modified_by: "Araman",
      })
      .then((res) => {
        setOpenSnackbar((prevState) => {
          return {
            ...prevState,
            open: true,
            message: " Project Master details updated Successfully",
          };
        });
      });
  };

  const goBack = () => {
    navigate("/project-master");
  };
  return (
    <>
      <MessageModal handleClose={handleClose} open={open} />
      <div className="createCustomerWrapper">
        <div className="masterCrudForm">
          {/* <TextField
            className={classes.TextField}
            label="Project Id"
            variant="outlined"
            size="medium"
            id="project_id"
            type="text"
            value={formValue.project_id || ""}
            name="project_id"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["project_id"] ? true : false,
              className: formValue["project_id"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label="Denmark Project Id"
            variant="outlined"
            size="medium"
            id="denmark_project_id"
            type="text"
            value={formValue.denmark_project_id || ""}
            name="denmark_project_id"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["denmark_project_id"] ? true : false,
              className: formValue["denmark_project_id"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          /> */}
          <TextField
            className={classes.TextField}
            label="Project Reference Id"
            variant="outlined"
            size="medium"
            id="project_ref_id"
            type="text"
            value={formValue.project_ref_id || ""}
            name="project_ref_id"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["project_ref_id"] ? true : false,
              className: formValue["project_ref_id"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label="Project Name"
            variant="outlined"
            size="medium"
            id="project_name"
            type="text"
            value={formValue.project_name || ""}
            name="project_name"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["project_name"] ? true : false,
              className: formValue["project_name"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          {/* <TextField
            className={classes.TextField}
            label="Customer Id"
            variant="outlined"
            size="medium"
            id="customer_id"
            type="text"
            value={formValue.customer_id || ""}
            name="customer_id"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["customer_id"] ? true : false,
              className: formValue["customer_id"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          /> */}
          <TextField
            className={classes.TextField}
            label="Customer ID"
            variant="outlined"
            size="medium"
            id="customer_id"
            select
            value={formValue.customer_id || ""}
            name="customer_id"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue?.["customer_id"] ? true : false,
              className: formValue?.["customer_id"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          >
            {customers.map((option) => (
              <MenuItem key={option.customer_id} value={option.customer_id}>
                {option.customer_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.TextField}
            label="Site Id"
            variant="outlined"
            size="medium"
            id="site_id"
            type="text"
            value={formValue.site_id || ""}
            name="site_id"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["site_id"] ? true : false,
              className: formValue["site_id"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label="Total Turbines"
            variant="outlined"
            size="medium"
            id="total_turbines"
            type="text"
            value={formValue.total_turbines || ""}
            name="total_turbines"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["total_turbines"] ? true : false,
              className: formValue["total_turbines"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label="Zone support Email"
            variant="outlined"
            size="medium"
            id="zone_support_email"
            type="text"
            value={formValue.zone_support_email || ""}
            name="zone_support_email"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["zone_support_email"] ? true : false,
              className: formValue["zone_support_email"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label="Zone Manager Email"
            variant="outlined"
            size="medium"
            id="zone_manager_email"
            type="text"
            value={formValue.zone_manager_email || ""}
            name="zone_manager_email"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["zone_manager_email"] ? true : false,
              className: formValue["zone_manager_email"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
          <TextField
            className={classes.TextField}
            label="status"
            variant="outlined"
            size="medium"
            id="status"
            type="text"
            value={formValue.status || ""}
            name="status"
            onChange={handleChange}
            InputLabelProps={{
              shrink: formValue["status"] ? true : false,
              className: formValue["status"]
                ? `${classes.textField_label}`
                : undefined,
            }}
          />
        </div>
        <div className="actionBtns crudBtnpadding">
          <Button
            variant="contained"
            onClick={update}
            style={{ margin: "auto 10px", width: "200px" }}
          >
            Update
          </Button>
          <Button
            variant="contained"
            onClick={goBack}
            style={{ margin: "auto 10px", width: "200px" }}
          >
            Back
          </Button>
        </div>
      </div>
      <Snackbar open={openSnackbar} setOpen={setOpenSnackbar} />
    </>
  );
};

export default ProjectMasterEdit;
