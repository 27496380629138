import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// scroll bar
import "simplebar/src/simplebar.css";

// third-party
import { Provider as ReduxProvider } from "react-redux";

// apex-chart
import "assets/third-party/apex-chart.css";

// project import
import App from "./App";
import { store } from "store";
import "bootstrap/dist/css/bootstrap.min.css";
import { HashRouter } from "../node_modules/react-router-dom/index";
import Interceptor from '../src/services/axios'
// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

ReactDOM.render(
  <StrictMode>
    <ReduxProvider store={store}>
      {/* <BrowserRouter> */}
      <HashRouter>
        <App />
        </HashRouter>
      {/* </BrowserRouter> */}
    </ReduxProvider>
  </StrictMode>,
  document.getElementById("root")
);

Interceptor.interceptor(store);


// reportWebVitals();
