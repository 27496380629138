import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Stack, Chip } from "@mui/material";
import {useNavigate} from "react-router-dom";
// project import
import DrawerHeaderStyled from "./DrawerHeaderStyled";
import Logo from "components/Logo";
import Typography from "@mui/material/Typography";
import "../DrwaerStyle.css";
import { Link } from "../../../../../node_modules/react-router-dom/index";
// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    // only available in paid version
    <DrawerHeaderStyled theme={theme} open={open} onClick={()=> navigate("/dashboard")} style={{cursor: "pointer", marginRight: "20px", paddingLeft: "unset"}}  >
      <Stack direction="row" spacing={1} alignItems="center" className="m-auto">
        <Typography sx={{ color: "white" }} variant="h3" component="div">
         Power Portal
        </Typography>
      </Stack>
      
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default DrawerHeader;
