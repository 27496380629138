import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

export default function MessageModal({open, handleClose,actionName = "OK", fullWidth,maxWidth}) {
return (
  <div>
    
    <Dialog
      open={open.open}
      onClose={handleClose}
      aria-labelledby="successraggable-dialog-title"
      fullWidth={fullWidth || null}
      maxWidth={maxWidth || null}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {open.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
         {open.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
      <Button autoFocus  variant="contained" onClick={handleClose}>
          Cancel
        </Button>
        <Button autoFocus  variant="contained" color={actionName? "error" : "primary"} onClick={open.onSubmit}>
          {actionName}
        </Button>
      </DialogActions>
    </Dialog>
  </div>
);
 
}