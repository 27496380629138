import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core";

export default function MessageSnackbar(props) {
  //const [open, setOpen] = React.useState(false);
  const useStyle = makeStyles((theme) => ({
    success: {
      backgroundColor: "green",
    },
    error: {
      backgroundColor: "red",
      whiteSpace: "pre-wrap",
    },
  }));
  const classes = useStyle();

  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "right",
  });

  useEffect(() => {
    setState((prev) => {
      return { ...prev, horizontal: "center" };
    });
  }, [props?.showCenter]);

  const { vertical, horizontal } = state;
  const { open, setOpen, error } = props;

  const handleClick = () => {
    setOpen((prevState) => {
      return {
        ...prevState,
        open: false,
      };
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen((prevState) => {
      return {
        ...prevState,
        open: false,
      };
    });
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClick}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={open.open}
        // autoHideDuration={10000}
        onClose={handleClose}
        message={open.message}
        action={action}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
        sx={{ width: "50%" }}
        ContentProps={{
          className: error ? classes.error : classes.success,
        }}
      />
    </div>
  );
}
