// material-ui
import * as React from "react";
import axios from "../../../../node_modules/axios/index";
import Loader from "components/Loader";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { CheckCircleOutlined, FrownOutlined } from "@ant-design/icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { styled } from "@mui/material/styles";
import Snackbar from "common/Snackbar";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export default function UploadFile() {
  const API_URL = process.env.REACT_APP_BACKEND_API;
  const [loader, setLoader] = React.useState(false);
  const [valRes, setValRes] = React.useState([]);
  const ref = React.useRef();
  const [error, setError] = React.useState(false);
  const [msg, setMsg] = React.useState("");

  const [selectedFile, setSelectedFile] = React.useState(null);
  const [openSnackbar, setOpenSnackbar] = React.useState({
    open: false,
    message: "",
  });

  const uploadReportHandler = () => {
    const formData = new FormData();
    formData.append("upload_file", selectedFile);
    const ext = selectedFile.name.split(".").pop();
    if (ext == "txt") {
      setLoader(true);
      axios.post(`${API_URL}uploadReport`, formData).then((res) => {
        resetBtnHandler();
        setLoader(false);
        setError(res.data.error);
        setMsg(res.data.message);
        setOpen(true);
      });
    } else if (ext == "xlsx" || ext == "csv") {
      setLoader(true);
      axios
        // .post(`${API_URL}importExcelV2`, formData)
        .post(`${API_URL}importFastExcel`, formData)
        .then((res) => {
          setLoader(false);
          resetBtnHandler();
          if (res.data.code === 201) {
            setValRes(res.data.data);
          } else {
            setError(res.data.error);
            setMsg(res.data.message);
            setOpen(true);
          }
        })
        .catch((err) => {
          setLoader(false);
          resetBtnHandler();
          setError(true);
          setMsg(err.message);
          setOpen(true);
        });
    } else {
      setOpenSnackbar((prevState) => {
        return {
          ...prevState,
          open: true,
          message: "Invalid file format",
        };
      });
    }
  };
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const resetBtnHandler = () => {
    ref.current.value = "";
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="container">
      {loader && <Loader />}

      <Card elevation={3}>
        <CardContent>
          <div className="row">
            <div className="col-8">
              <input
                type="file"
                className="form-control"
                name="reportUpload"
                ref={ref}
                onChange={handleFileSelect}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .txt"
              />
            </div>
            <div className="col-4">
              {/* <input type="date" className="form-control" name="reportDate" /> */}
              <Button
                variant="contained"
                onClick={uploadReportHandler}
                style={{ margin: "auto 10px" }}
              >
                Upload
              </Button>
              <Button
                variant="contained"
                style={{ margin: "auto 10px" }}
                onClick={resetBtnHandler}
              >
                Reset
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
      <Snackbar open={openSnackbar} setOpen={setOpenSnackbar} />
      <br />
      {valRes.length > 0 && (
        <>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Row</TableCell>
                <TableCell align="center">Attribute</TableCell>
                <TableCell align="center">Error</TableCell>
                <TableCell align="center">Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {valRes?.map((row, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell align="center" style={{ color: "red" }}>
                      {row?.row}
                    </TableCell>
                    <TableCell align="center" style={{ color: "red" }}>
                      {row?.attribute}
                    </TableCell>
                    <TableCell align="center" style={{ color: "red" }}>
                      {row?.errors}
                    </TableCell>
                    <TableCell align="center" style={{ color: "red" }}>
                      {row?.values[row?.attribute]}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent align="center">
          {error ? (
            <FrownOutlined style={{ fontSize: "50px", color: "red" }} />
          ) : (
            <CheckCircleOutlined style={{ fontSize: "50px", color: "green" }} />
          )}
          <DialogContentText id="alert-dialog-description" mt={2}>
            {msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus className="m-auto">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
