// material-ui
import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import axios from "axios";
import Loader from "components/Loader";

import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@mui/material/Paper";
import MUIDataTable from "mui-datatables";
import MessageModal from "common/MessageModal";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import "../masterStyles.css";
import PopupComponent from "common/popup";
import LocationCreateNew from "./LocationCreateNew";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    " & .MuiPaper-root": {
      boxShadow: "0px 3px 15px rgb(0 0 0 / 10%)",
      borderRadius: "8px",
      flex: "1",
    },
  },
  columnHeader: {
    "& span": {
      "& Button": {
        fontWeight: "600",
        fontFamily: "Poppins, sans-serif",
      },
    },
  },
  arrangeCenter: {
    textAlign: "center",
  },
  centeredHeader: {
    "& span": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  rowFont: {
    " & div:nth-child(2)": {
      fontFamily: "Poppins, sans-serif",
      fontSize: "12px",
      fontWeight: "500",
    },
  },
}));

const LocationList = () => {
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
  });
  const [loader, setLoader] = React.useState(false);

  const API_URL = process.env.REACT_APP_BACKEND_API;
  const loadLocations = () => {
    setLoader(true);
    axios.get(`${API_URL}location`).then((res) => {
      setRows(res.data.data);
      setLoader(false);
    });
  };
  React.useEffect(() => {
    loadLocations();
  }, []);

  const navigate = useNavigate();
  const classes = useStyles();

  const editSite = (id) => {
    setShowPopup(true);
    setRowId(id);
  };
  const tableStyle = {
    rows: {
      fontSize: "25px",
      color: "red",
    },
    header: {
      fontWeight: 600,
    },
    MUIDataTableBodyCell: {
      head: {
        backgroundColor: "red !important",
      },
    },
    MUIDataTableHeadCell: {
      display: "flex",
      justifyContent: "center",
    },
  };
  const columns = [
    {
      name: "location_id",
      label: "Location ID",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
      },
    },
    {
      name: "location_name",
      label: "Location Name",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
      },
    },
    {
      name: "country_id",
      label: "Country  ID",
      options: {
        filter: true,
        sort: true,
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
      },
    },

    {
      name: "Actions",
      options: {
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div style={{ display: "flex" }} className="actionBtns">
              <Button
                aria-label="edit"
                onClick={() => editSite(rows[dataIndex].location_id)}
              >
                <ModeEditOutlineOutlinedIcon sx={{ color: "#0a0a9bde" }} />
              </Button>
              <Button
                aria-label="edit"
                onClick={() => deleteSite(rows[dataIndex].location_id)}
              >
                <DeleteOutlineOutlinedIcon sx={{ color: "#dc0000" }} />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const deleteSite = (id) => {
    setOpen((prevState) => {
      return {
        open: true,
        content: "Are you sure you want to delete?",
        onSubmit: () => {
          axios.delete(`${API_URL}location/${id}`).then((res) => {
            setOpen((prevState) => {
              return {
                open: false,
              };
            });
            setOpenSnackbar((prevState) => {
              return {
                ...prevState,
                open: true,
                message: " Location details deleted Successfully",
              };
            });
            loadLocations();
          });
        },
        actionName: "Delete",
      };
    });
    // axios.delete(`${API_URL}customer/${id}`).then((res) => {
    //   console.log("Res==>", res.data);
    //   setOpen((prevState) => {
    //     return {
    //       open: true,
    //       content: res.data.message,
    //       title: res.data.code,
    //     };
    //   });
    //   loadCustomer();
    // });
  };

  const options = {
    selectableRows: false,
  };

  const [rows, setRows] = React.useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [rowId, setRowId] = useState(null);

  const [project, setProject] = React.useState("");
  const [value, setValue] = React.useState(new Date());
  const [open, setOpen] = React.useState({});

  const handleClose = (title = "", content = "") => {
    setOpen((prevState) => {
      return {
        open: false,
      };
    });
  };

  return (
    <div className="table-container">
      {loader && <Loader />}

      <Divider />
      {rows?.length > 0 ? (
        <div>
          <div className={`${classes.root}`}>
            <MessageModal handleClose={handleClose} open={open} />
            <MUIDataTable
              data={rows}
              columns={columns}
              options={options}
              elevation={4}
              customTheme={tableStyle}
              title={
                <Button
                  variant="contained"
                  onClick={() => {
                    setShowPopup(true);
                    setRowId(null);
                  }}
                >
                  Create
                </Button>
              }
            />
          </div>
        </div>
      ) : (
        <TableContainer component={Paper} className="mt-3">
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">No Data Found</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      )}
      {/* <Snackbar open={openSnackbar} setOpen={setOpenSnackbar} /> */}
      <PopupComponent
        open={showPopup}
        onClose={setShowPopup}
        title={rowId ? "Update Location" : "Create Location"}
        width={"25%"}
      >
        <LocationCreateNew
          id={rowId}
          onClose={setShowPopup}
          refreshData={() => loadLocations()}
        />
      </PopupComponent>
    </div>
  );
};

export default LocationList;
