import axios from 'axios';
import { START_LOADER } from 'store/reducers/actions';

const interceptor = (store) => {
    axios.interceptors.request.use(
        (conf) => {
            // store.dispatch({
            //     type: START_LOADER,
            // });
            const token = "Bearer "+sessionStorage.getItem('token');
            conf.headers['Authorization'] = token;
            return conf;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    axios.interceptors.response.use(
        (next) => {
            // store.dispatch({
            //     type: STOP_LOADER,
            // });
            return Promise.resolve(next);
        },
        (error) => {
            // You can handle error here and trigger warning message without get in the code inside
            // store.dispatch({
            //     type: START_LOADER,
            // });
            return Promise.reject(error);
        }
    );
};
export default {
    interceptor,
};