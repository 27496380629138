import React, { useState } from "react";
import { Button,  Avatar} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "common/Snackbar";
import Chip from '@mui/material/Chip';

const useStyles = makeStyles((theme) => ({
  TextField: {
    width: "330px",
  },
  textField_label: {
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
  },
  hideLabel: {
    display: "none",
  },
  container : {
    width:"400px",
    margin: "120px auto 120px",
    backgroundColor:" #fff",
    padding: "0 20px 20px",
    boxShadow: "0px 3px 15px rgb(0 0 0 / 10%)",
    borderRadius: "8px",
    textAlign: "center",
    fontFamily: "Poppins, sans-serif"
  },
  avatarFlip :{
    borderRadius: "100px",
    overflow: "hidden",
    height: "150px",
    width: "150px",
    position: "relative",
    margin: "auto",
    top: "-60px",
    boxShadow: "0 0 0 13px #f0f0f0",
  },
  avatarImg :{
    position: "absolute",
    left: "0",
    top: "0",
    borderRadius: "100px",
    width: "100%",
    height: "100%",
    },
    cName: {
      fontSize: "32px",
      fontWeight: "600",
      marginBottom: "15px",
      color: "#333"
    },
    cType : {
      fontSize: "13px",
      color: "#00baff",
      letterSpacing: "1px",
      marginBottom: "25px"
    },
    cEmail: {
      fontSize: "16px",
      lineHeight: "26px",
      marginBottom: "20px",
      color: "#666"
    },
    header: {
      fontFamily: "Poppins, sans-serif",
      fontSize: "32px",
      fontWeight: "600",
      marginBottom: "15px",
      color: "#333",
      width: "80%",
      margin: "auto",
      textAlign: "center",
    }
}));

const ViewProfile = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
  });
  const [userData, setUserData] = useState({});
  React.useEffect(() => {
    const fetchData = JSON.parse(localStorage.getItem("userData"));
    setUserData(fetchData);
  }, []);

  const goBack = () => {};

  const { customer_id, customer_email, customer_name, user_id, user_type, user_type_desc} = userData;
  return (
    <>
      <h4 className={classes.header} >User Profile</h4>
      <div className={classes.container}>
        <div className={classes.avatarFlip} >
        <Avatar
              alt="profile user"
              onClick={null}
              src={""}
              // sx={{ width: 32, height: 32, marginLeft: "auto" }}
              classes={{root: classes.avatarImg}}
            />
        </div>
        <h2 className={classes.cName}>{customer_id}</h2>
        <h2 className={classes.cName}>{customer_name}</h2>
        <p className={classes.cType}><Chip  color="info" size="small" label={user_type_desc}/></p>
        <p className={classes.cEmail}>{customer_email}</p>
      </div>
        <Snackbar open={openSnackbar} setOpen={setOpenSnackbar} />
    </>
  );
};

export default ViewProfile;
