// material-ui
import {
  Box,
  IconButton,
  Link,
  useMediaQuery,
  Stack,
  Avatar,
  Typography,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  Tab,
  Tabs,
} from "@mui/material";
import { GithubOutlined } from "@ant-design/icons";
// import { useRef, useState } from 'react';
// project import
import Search from "./Search";
import React, { useState, useRef } from "react";
import Profile from "./Profile";
import { useNavigate } from "react-router-dom";
import MobileSection from "./MobileSection";
import { useTheme } from "@mui/material/styles";

import MainCard from "components/MainCard";
import Transitions from "components/@extended/Transitions";

import PropTypes from "prop-types";
// assets
import avatar1 from "assets/images/users/avatar-1.png";
import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import SettingTab from "./Profile/SettingTab";
import ProfileTab from "./Profile/ProfileTab";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { clear_data, projects_list } from "store/actions/reportaction";
// ==============================|| HEADER - CONTENT ||============================== //

const handleCustomerId = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const customerId =
    typeof userData?.customer_id == "undefined" ? "" : userData?.customer_id;
  return customerId;
};

const HeaderContent = () => {
  let navigate = useNavigate();

  const API_URL = process.env.REACT_APP_BACKEND_API;

  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const theme = useTheme();

  const handleLogout = async () => {
    // logout
    localStorage.clear();
    dispatch(clear_data());
    navigate("/");
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [value, setValue] = useState(0);
  const [loader, setLoader] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel({ children, value, index, ...other }) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`profile-tabpanel-${index}`}
        aria-labelledby={`profile-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `profile-tab-${index}`,
      "aria-controls": `profile-tabpanel-${index}`,
    };
  }
  const iconBackColorOpen = "grey.300";
  const dispatch = useDispatch();
  const custId = handleCustomerId();

  const fetchProjectList = () => {
    setLoader(true);
    const projectUrl =
      custId == ""
        ? `${API_URL}getAllActiveProjectLists`
        : `${API_URL}getAllActiveProjectList/${custId}`;
    axios.get(projectUrl).then((res) => {
      const datares = res.data.data;
      datares.forEach(function (arrayItem) {
        var x = arrayItem.project_name;
        arrayItem.label = arrayItem.project_name;
      });
      dispatch(projects_list(datares));
      setLoader(false);
    });
  };
  const [userData, setUserData] = useState({});
  React.useEffect(() => {
    fetchProjectList();
  }, []);
  React.useEffect(() => {
    const fetchData = JSON.parse(localStorage.getItem("userData"));
    setUserData(fetchData);
  }, []);

  return (
    <div>
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}

      {!matchesXs && (
        <div style={{ width: "100%" }}>
          {/* <ButtonBase
                sx={{
                    p: 0.25,
                    bgcolor: open ? iconBackColorOpen : 'transparent',
                    borderRadius: 1,
                    '&:hover': { bgcolor: 'secondary.lighter' },
                    marginLeft:'auto'
                }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
               
            </ButtonBase> */}

          <Popper
            placement="right"
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            disablePortal
            popperOptions={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: "1000,100",
                  },
                },
              ],
            }}
            style={{ right: "10px" }}
          >
            {({ TransitionProps }) => (
              <Transitions type="fade" in={open} {...TransitionProps}>
                {open && (
                  <Paper
                    sx={{
                      boxShadow: theme.customShadows.z1,
                      width: 290,
                      minWidth: 240,
                      maxWidth: 290,
                      mr: 0,
                      ml: "auto",
                      mt: 7,
                      [theme.breakpoints.down("md")]: {
                        maxWidth: 250,
                      },
                    }}
                  >
                    <ClickAwayListener onClickAway={handleClose}>
                      <MainCard elevation={0} border={false} content={false}>
                        <CardContent sx={{ px: 2.5, pt: 3 }}>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item>
                              <Stack
                                direction="row"
                                spacing={1.25}
                                alignItems="center"
                              >
                                <Avatar
                                  alt="profile user"
                                  src={""}
                                  sx={{ width: 32, height: 32 }}
                                />
                                <Stack>
                                  <Typography variant="h6">
                                    {userData?.customer_name || "root"}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {userData?.user_type_desc +
                                      " (" +
                                      userData?.user_type +
                                      ")" || ""}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Grid>
                            <Grid item>
                              <IconButton
                                size="large"
                                color="secondary"
                                onClick={handleLogout}
                              >
                                <LogoutOutlined />
                              </IconButton>
                            </Grid>
                          </Grid>
                          {/* <Grid item>
                          <Typography
                                    variant="body2"
                                    color="black"
                                  >
                              Customer Id :{customer_id}
                                  </Typography>
                                  </Grid>
                                  <Grid item>
                          <Typography
                                    variant="body2"
                                    color="black"
                                  >
                              User Status :    {user_status||null}
                                  </Typography>
                                  </Grid>
                                   */}
                        </CardContent>
                        {open && (
                          <>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                              <Tabs
                                variant="fullWidth"
                                value={value}
                                onChange={handleChange}
                                aria-label="profile tabs"
                              >
                                <Tab
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textTransform: "capitalize",
                                  }}
                                  icon={
                                    <UserOutlined
                                      style={{
                                        marginBottom: 0,
                                        marginRight: "10px",
                                      }}
                                    />
                                  }
                                  label="Profile"
                                  {...a11yProps(0)}
                                />
                                {/* <Tab
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textTransform: "capitalize",
                                  }}
                                  icon={
                                    <SettingOutlined
                                      style={{
                                        marginBottom: 0,
                                        marginRight: "10px",
                                      }}
                                    />
                                  }
                                  label="Setting"
                                  {...a11yProps(1)}
                                /> */}
                              </Tabs>
                            </Box>
                            <TabPanel
                              value={value}
                              index={0}
                              dir={theme.direction}
                            >
                              <ProfileTab handleLogout={handleLogout} />
                            </TabPanel>
                            {/* <TabPanel
                              value={value}
                              index={1}
                              dir={theme.direction}
                            >
                              <SettingTab />
                            </TabPanel> */}
                          </>
                        )}
                      </MainCard>
                    </ClickAwayListener>
                  </Paper>
                )}
              </Transitions>
            )}
          </Popper>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{ p: 0.5, width: "100%", cursor: "pointer" }}
          >
            <Avatar
              alt="profile user"
              onClick={handleToggle}
              src={""}
              sx={{ width: 32, height: 32, marginLeft: "auto" }}
            />
            <Typography
              variant="subtitle1"
              onClick={handleToggle}
              sx={{ marginLeft: "auto" }}
            >
              {userData?.customer_name || "root"}
            </Typography>
          </Stack>
        </div>
      )}

      {matchesXs && <MobileSection />}
    </div>
  );
};

export default HeaderContent;
